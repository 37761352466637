<div class="prompt-wrapper" [ngClass.lt-sm]="'mobile'">
    <mat-form-field class="prompt-input">
        <textarea
            class="prompt-textarea"
            (keydown.enter)="finishClicked()"
            [(ngModel)]="promptText"
            autocomplete="off"
            cdkTextareaAutosize
            matInput
            placeholder="{{ placeholderText }}"
            (focus)="focusOnInput()"
            (change)="inputChanged()"
            (paste)="inputChanged()"
            (keydown)="inputChanged()">
        </textarea>
        <!--        <div-->
        <!--            class="menu-button-wrapper"-->
        <!--            matTooltip="Choose a tone of voice for the generated script"-->
        <!--            *ngIf="-->
        <!--                toneOfVoiceList && toneOfVoice && (showSettings$ | async) && promptType !== PromptTypeEnum.LONG_SCRIPT-->
        <!--            ">-->
        <!--            <button mat-flat-button class="small-icon-button" [matMenuTriggerFor]="menu">-->
        <!--                <mat-icon>arrow_drop_down</mat-icon>-->
        <!--                {{ toneOfVoice }}-->
        <!--            </button>-->
        <!--            <mat-menu #menu="matMenu">-->
        <!--                <button mat-menu-item (click)="setTone(tone)" *ngFor="let tone of toneOfVoiceList">-->
        <!--                    {{ tone.id }}-->
        <!--                </button>-->
        <!--            </mat-menu>-->
        <!--        </div>-->
    </mat-form-field>

    <div class="script-preferences">
        <ng-container *ngIf="(showSettings$ | async) && promptType !== PromptTypeEnum.LONG_SCRIPT">
            <div
                class="menu-button-wrapper"
                matTooltip="Choose a tone of voice for the generated script"
                *ngIf="toneOfVoiceList && toneOfVoice">
                <button [matMenuTriggerFor]="menu" mat-button>
                    <mat-icon>arrow_drop_down</mat-icon>
                    {{ toneOfVoice }} Tone
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="setTone(tone)" *ngFor="let tone of toneOfVoiceList">
                        {{ tone.id }}
                    </button>
                </mat-menu>
            </div>

            <button class="video-length" mat-button [matMenuTriggerFor]="videoLengthMenu" matTooltip="Video length">
                <mat-icon>arrow_drop_down</mat-icon>

                {{ selectedVideoLength.key }}
            </button>
            <mat-menu #videoLengthMenu>
                <button mat-menu-item (click)="setLength(length)" *ngFor="let length of videoLengths">
                    {{ length.key }} {{ '( ~' + length.value + ' mins)' }}
                </button>
            </mat-menu>

            <button
                *ngIf="storytellingTechniqueList"
                mat-button
                (click)="openStorytellingDialog()"
                [class.has-value]="storytellingTechnique"
                matTooltip="Use a storytelling technique to create a structured script">
                <mat-icon>arrow_drop_down</mat-icon>

                {{ this.storytellingTechnique ?? 'Script Structure' }}
            </button>
        </ng-container>
    </div>
</div>
<button
    (click)="finishClicked()"
    [disabled]="!promptText || promptText.trim() === ''"
    aria-label="Next"
    class="prompt-button icon-last"
    color="primary"
    mat-flat-button
    matSuffix>
    <div class="continue-wrapper">
        <ng-container *ngIf="!loading; else loader">
            <span>Continue</span>
            <mat-icon matTextSuffix>navigate_next</mat-icon>
        </ng-container>
    </div>
    <ng-template #loader>
        <div class="uploader-status row-centered gapped">
            <mat-progress-spinner
                strokeWidth="4"
                class="loader-icon white-spinner"
                color="white"
                mode="indeterminate"
                diameter="25"></mat-progress-spinner>
        </div>
    </ng-template>
</button>
<!--    <div class="menu-button-wrapper">-->
<!--        <button mat-flat-button class="small-icon-button" [matMenuTriggerFor]="menu">-->
<!--            <mat-icon class="url-icon" *ngIf="currentIcon === IconType.SIMPLE_PROMPT">expand_circle_down</mat-icon>-->
<!--            <mat-icon class="url-icon" *ngIf="currentIcon === IconType.LINK">link</mat-icon>-->
<!--            <img-->
<!--                class="svg-icon"-->
<!--                *ngIf="currentIcon === IconType.GOOGLE_DOCS"-->
<!--                src="assets/images/icons/google/Google_Docs_logo.svg" />-->
<!--            <img-->
<!--                class="svg-icon"-->
<!--                *ngIf="currentIcon === IconType.GOOGLE_SLIDES"-->
<!--                src="assets/images/icons/google/Google_Slides_Logo.svg" />-->
<!--            <mat-icon class="long-script-icon" *ngIf="currentIcon === IconType.LONG_SCRIPT"> subject</mat-icon>-->
<!--            {{ currentIcon }}-->
<!--        </button>-->
<!--        <mat-menu #menu="matMenu">-->
<!--            <button mat-menu-item (click)="setMode(IconType.SIMPLE_PROMPT)">-->
<!--                <mat-icon>text_fields</mat-icon>-->
<!--                <span>Simple prompt</span>-->
<!--            </button>-->
<!--            <button mat-menu-item (click)="setMode(IconType.LONG_SCRIPT)">-->
<!--                <mat-icon>subject</mat-icon>-->
<!--                <span>Use my own script</span>-->
<!--            </button>-->

<!--            <button mat-menu-item (click)="setMode(IconType.LINK)">-->
<!--                <mat-icon>link</mat-icon>-->
<!--                <span>Link</span>-->
<!--            </button>-->
<!--            <button mat-menu-item (click)="setMode(IconType.GOOGLE_DOCS)" class="svg-icon-button">-->
<!--                <img class="svg-icon" src="assets/images/icons/google/Google_Docs_logo.svg" />-->
<!--                <span>Google Docs</span>-->
<!--            </button>-->
<!--            <button mat-menu-item (click)="setMode(IconType.GOOGLE_SLIDES)" class="svg-icon-button">-->
<!--                <img class="svg-icon" src="assets/images/icons/google/Google_Slides_Logo.svg" />-->
<!--                <span>Google Slides</span>-->
<!--            </button>-->
<!--        </mat-menu>-->
<!--    </div>-->
