import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MissingArgumentsError } from '../../../../models/errors/general.errors';
import { IUpdateChosenTakeRequest } from '../../../http-models/project/scene/scene-http-request-model';
import { INewChosenTakeSocketData } from '../../../../models/socket-events/project/scene/socket-scene-events';
import { getUpdateChosenTakeUrlPUT } from '../../../../constants/private/urls/projects/take.urls';
import {
    getProjectSpecificSceneUrl,
    updateSceneCompositionPUTUrl,
} from '../../../../constants/private/urls/projects/scene.urls';
import { CompositionTypeEnum } from '../../../../models/defines';
import { ISceneInDTO } from '../../../../models/project/scene-model';

@Injectable()
export class SceneApiService {
    constructor(private http: HttpClient) {}

    getScene$(projectId: string, sceneId: string) {
        const url = getProjectSpecificSceneUrl(projectId, sceneId);

        return this.http.get<ISceneInDTO>(url);
    }
    updateChosenTake$(projectId: string, sceneId: string, chosenTakeId: string) {
        if (!projectId || !sceneId || !chosenTakeId) {
            throw new MissingArgumentsError(`Could not update chosen take because on of the arguments is null`);
        }

        const specificSceneUrl = getUpdateChosenTakeUrlPUT(projectId, sceneId);
        const body: IUpdateChosenTakeRequest = {
            newChosenTakeId: chosenTakeId,
        };
        return this.http.put<INewChosenTakeSocketData>(specificSceneUrl, body);
    }

    updateSceneComposition$(projectId: string, sceneId: string, composition: string | CompositionTypeEnum) {
        if (!projectId || !sceneId || !composition) {
            throw new MissingArgumentsError(`Could not update scene composition because one of the arguments is null`);
        }
        const url = updateSceneCompositionPUTUrl(projectId, sceneId);
        return this.http.put<ISceneInDTO>(url, { composition: composition });
    }
}
