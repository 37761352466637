import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SnackbarActionEnum, SnackBarService } from '../../../../../../services/utils/snack-bar.service';
import { IToneOfVoice, PromptTypeEnum } from 'src/app/models/defines';
import { MatDialog } from '@angular/material/dialog';
import { GoogleLinkGuideComponent } from './google-link-guide/google-link-guide.component';
import { BehaviorSubject } from 'rxjs';
import {
    IStorytellingChooserData,
    StorytellingChooserComponent,
} from '../storytelling-chooser/storytelling-chooser.component';

export interface IPromptAndPrefferences {
    prompt: string;
    toneOfVoice: string;
    videoLength: number;
    storytellingTechnique: string;
}

@Component({
    selector: 'prompt',
    templateUrl: './prompt.component.html',
    styleUrl: './prompt.component.scss',
})
export class PromptComponent implements OnChanges, AfterViewInit {
    @Output('finish') finish = new EventEmitter<IPromptAndPrefferences>();
    @Input('videoTag') videoTag = null;
    @Input('toneOfVoiceList') toneOfVoiceList = null;
    @Input('storytellingTechniqueList') storytellingTechniqueList = null;
    @Input('promptType') promptType: PromptTypeEnum = null;
    @Input('injectPrompt') injectPrompt: string;
    @Output() showSettingsChange = new EventEmitter<boolean>();
    @Input() loading: boolean = false;

    promptText: string = '';
    placeholderText: string = 'Enter text here...';
    readonly LONG_SCRIPT_THRESHOLD = 300; // Constant for long script detection
    IconType = PromptTypeEnum;
    currentIcon: PromptTypeEnum = PromptTypeEnum.SIMPLE_PROMPT;
    snackbarShown = { url: false, longScript: false };
    toneOfVoice: string;
    showSettings$ = new BehaviorSubject<boolean>(false);
    videoLengths: { key: string; value: number }[] = [
        { key: 'Short Form', value: 2 },
        { key: 'Medium Length', value: 5 },
        { key: 'Long Form', value: 10 },
    ];
    selectedVideoLength = this.videoLengths[1];
    storytellingTechnique: string = null;
    urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;

    constructor(
        private snackBar: SnackBarService,
        private dialog: MatDialog
    ) {
        this.showSettings$.subscribe((value) => this.showSettingsChange.emit(value));
    }

    ngAfterViewInit(): void {
        if (this.injectPrompt) {
            this.prompt = this.injectPrompt;
        }
    }

    get prompt() {
        return this.promptText;
    }

    set prompt(prompt: string) {
        this.promptText = prompt;
        this.inputChanged();
    }

    setLength(videoLengthRecord: { key: string; value: number }) {
        this.selectedVideoLength = videoLengthRecord;
    }

    focusOnInput() {
        this.showSettings$.next(true);
    }

    openStorytellingDialog() {
        const dialogData: IStorytellingChooserData = {
            selectedTechnique: this.storytellingTechnique,
            storytellingTechniqueList: this.storytellingTechniqueList,
        };
        const dialogRef = this.dialog.open(StorytellingChooserComponent, {
            data: dialogData,
            width: '500px',
            height: '500px',
            panelClass: 'padded-dialog',
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) return;

            this.storytellingTechnique = result.chosen;
        });
    }

    inputChanged() {
        this.detectFeatures();
        if (this.promptText?.length > 0) {
            this.showSettings$.next(true);
        }
        // console.log(this.promptText);
    }

    detectFeatures() {
        const isUrl = this.detectUrl(this.promptText);
        const isGoogleDocs = isUrl && this.promptText.includes('docs.google.com/document');
        const isGoogleSlides = isUrl && this.promptText.includes('docs.google.com/presentation');
        const isLongScript = this.promptText.length >= this.LONG_SCRIPT_THRESHOLD;

        // Determine which icon should display
        if (isLongScript) {
            this.setIcon(PromptTypeEnum.LONG_SCRIPT, '👌 Script detected');
        } else if (isGoogleDocs) {
            this.setIcon(PromptTypeEnum.GOOGLE_DOCS, '👍 Google Docs link detected. Make sure it has public access.');
        } else if (isGoogleSlides) {
            this.setIcon(
                PromptTypeEnum.GOOGLE_SLIDES,
                '👍 Google Slides link detected. Make sure it has public access.'
            );
        } else if (isUrl) {
            this.setIcon(PromptTypeEnum.LINK, '👌 Link detected');
        } else {
            this.removeIcon();
        }
    }

    detectUrl(text: string): boolean {
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        return urlPattern.test(text);
    }

    setIcon(icon: PromptTypeEnum, snackbarMessage: string) {
        if (this.currentIcon !== icon) {
            this.currentIcon = icon;

            this.showSnackbarOnce(icon, snackbarMessage);
        }
    }

    removeIcon() {
        if (this.currentIcon && this.currentIcon !== PromptTypeEnum.SIMPLE_PROMPT) {
            this.snackbarShown[this.currentIcon] = false;
            this.currentIcon = PromptTypeEnum.SIMPLE_PROMPT;
        }
    }

    showSnackbarOnce(icon: PromptTypeEnum, message: string) {
        if (!this.snackbarShown[icon]) {
            this.snackbarShown[icon] = true;

            if ([PromptTypeEnum.GOOGLE_DOCS, PromptTypeEnum.GOOGLE_SLIDES].includes(icon)) {
                this.snackBar
                    .openMessage(message, SnackbarActionEnum.Learn_How, 7000)
                    .onAction()
                    .subscribe(() => this.openLinkGuide());
            } else {
                this.snackBar.openMessage(message, null, 3000);
            }
        }
    }

    openLinkGuide() {
        this.dialog.open(GoogleLinkGuideComponent, {
            width: '500px',
            height: '500px',
            panelClass: 'google-link-guide',
        });
    }

    finishClicked() {
        if (this.promptText.length < 5) {
            this.snackBar.openMessage('🫣 Oops! The text you entered is too short', SnackbarActionEnum.Dismiss, 5000);
            return;
        }
        if (this.promptType === PromptTypeEnum.LINK) {
            const match = this.promptText.match(this.urlRegex);
            if (!match) {
                this.snackBar.openMessage(
                    '🫣 Oops! The url you entered is not valid',
                    SnackbarActionEnum.Dismiss,
                    5000
                );
                return;
            }
        }
        this.finish.emit({
            prompt: this.promptText,
            toneOfVoice: this.toneOfVoice,
            videoLength: this.selectedVideoLength.value,
            storytellingTechnique: this.storytellingTechnique,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        /// Detect when prompt type changes and change the placeholder text accordingly
        if (changes['promptType']) {
            /// Switch case all prompt types
            switch (this.promptType) {
                case PromptTypeEnum.LONG_SCRIPT:
                    this.placeholderText = 'Enter your script here...';
                    break;
                case PromptTypeEnum.SIMPLE_PROMPT:
                    this.placeholderText = 'Enter the subject of your video';
                    break;
                case PromptTypeEnum.LINK:
                    this.placeholderText = 'Enter a link to a blog, Google Docs or Google Slides...';
                    break;
                case PromptTypeEnum.GOOGLE_DOCS:
                    this.placeholderText = 'Enter Google Docs link here...';
                    break;
                case PromptTypeEnum.GOOGLE_SLIDES:
                    this.placeholderText = 'Enter Google Slides link here...';
                    break;
            }
        }
        if (changes['toneOfVoiceList']) {
            if (this.toneOfVoiceList && this.toneOfVoiceList.length > 0) {
                this.toneOfVoice = this.toneOfVoiceList[0].id;
            }
        }
    }

    setTone(tone: IToneOfVoice) {
        this.toneOfVoice = tone.id;
    }

    setMode(icon: PromptTypeEnum) {
        this.currentIcon = icon;
        switch (icon) {
            case PromptTypeEnum.LINK:
                this.showSnackbarOnce(icon, '👌 Mode set to Link');
                break;
            case PromptTypeEnum.GOOGLE_DOCS:
                this.showSnackbarOnce(icon, '👌 Mode set to Google Docs');
                break;
            case PromptTypeEnum.GOOGLE_SLIDES:
                this.showSnackbarOnce(icon, '👌 Mode set to Google Slides');
                break;
            case PromptTypeEnum.LONG_SCRIPT:
                this.showSnackbarOnce(icon, '👌 Mode set to script');
                break;
            case PromptTypeEnum.SIMPLE_PROMPT:
                this.showSnackbarOnce(icon, '👌 Mode set to prompt');
                break;
        }
    }

    protected readonly PromptTypeEnum = PromptTypeEnum;
}
