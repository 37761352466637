<div class="dialog-wrapper">
    <ng-container *ngIf="this.data.toEnableExport; else disableExport">
        <div class="lottie-icon-wrapper" [ngClass.lt-sm]="'mobile'">
            <ng-lottie
                #animatedIcon
                class="animated-icon-anim"
                containerClass="animated-icon"
                [options]="{
                    path: this.assetBasePath + this.congratsLottieAnimation,
                    autoplay: true,
                    loop: true,
                }"></ng-lottie>
        </div>
        <div class="header">
            Hang tight — almost done!
            <br />
        </div>
        <div class="msg">
            Your video should take about 5-10 minutes of processing.<br />
            We'll shoot you an email when it's all ready. <br />Thanks for your patience! You're going to love it!
            🎉.<br />
        </div>
        <div class="loading-text">
            {{
                (isLoading$ | async)
                    ? 'Please keep this window open until this message disappears'
                    : 'Upload Done! you can go back to library'
            }}
        </div>

        <button
            [disabled]="isLoading$ | async"
            class="close-button"
            color="primary"
            mat-raised-button
            (click)="closeDialog(true)">
            {{ (isLoading$ | async) ? 'Uploading...' : 'Homepage' }}
        </button>
    </ng-container>

    <ng-template #disableExport>
        <div class="header">
            Wait a sec!
            <br />
        </div>
        <div class="msg">
            Before you hit again the export button, <br />
            double-check that the process is finished and the progress bar is at 100%.
        </div>

        <button class="close-button" color="primary" mat-raised-button (click)="closeDialog(false)">Got it!</button>
    </ng-template>
</div>
