import { Component, OnDestroy, OnInit } from '@angular/core';
import { IProjectInDTO } from '../../../../models/project/project-model';
import { ProjectGeneralRouterService } from 'src/app/services/project/routes/project-general-router.service';
import { HomepageAuthApiService, IGetHomeDataParams } from '../../../../services/api/auth/homepage-auth-api.service';
import { ProjectRecordingTypeEnum } from '../../../../models/defines';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { IBaseTemplate, IHomepageData } from '../../../../models/homepage/homepage-defines';
import { StudioCarouselConfig } from '../project/studio/studio-animations-and-carousel';
import { MatDialog } from '@angular/material/dialog';
import { TemplateDialogComponent } from './template-dialog/template-dialog.component';
import { IUserVideoIdea, User, UserVideoIdeaStatusEnum } from '../../../../models/user';
import { handleApiCallAsync } from '../../../../utils/general-functions';
import { NavigationHelperService } from '../../../../services/utils/navigation-helper.service';
import { INewProjectRequest, ProjectAuthApiService } from 'src/app/services/api/auth/projects/project-auth-api.service';
import { UserStateManagerService } from '../../../../services/state-management/user/user-state-manager.service';
import { ProfileService } from '../../../../services/show/profile.service';
import { ArtDirectorService } from '../../../../services/art-director.service';
import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import { AnalyticsNotifierService } from '../../../../services/utils/analytics-notifier.service';
import { UserProjectEventListenerService } from '../../../../services/state-management/user/socket/listeners/user-project-event-listener.service';
import { ProjectMetadataManagerService } from '../../../../services/state-management/project/project-metadata/project-metadata-manager.service';

@Component({
    selector: 'app-home',
    templateUrl: './homepage.component.html',
    styleUrl: './homepage.component.scss',
})
export class HomepageComponent implements OnInit, OnDestroy {
    loadingVideoIdeas = false;
    loadingTemplates = false;
    loadingProjects = false;
    lastProjects: IProjectInDTO[] = [];
    templates: IBaseTemplate[] = [];
    videoIdeas: IUserVideoIdea[] = [];
    videoIdeaStatus: UserVideoIdeaStatusEnum;
    RecordingType = ProjectRecordingTypeEnum;
    UserVideoIdeaStatusEnum = UserVideoIdeaStatusEnum;
    templateSliderConfig = {
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        dots: false,
        variableWidth: true,
        swipe: false,
        swipeToSlide: false,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 7,
                },
            },
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
        // responsive: [
        //   {
        //     breakpoint: 1300,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        //   {
        //     breakpoint: 800,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        //   {
        //     breakpoint: 600,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        // ],
    };
    lottieOptions = {
        path: 'assets/animations/production/rocket.json',
        autoplay: true,
        loop: true,
    };

    private onDestroy$ = new Subject<void>();

    personalizedDynamics: IDynamicLottieChange[] = [];
    dummyCards = Array(3); // Creates an array with 3 items for the static cards
    displayedVidedIdeas: IUserVideoIdea[] = [];
    user: User;
    constructor(
        private projectGeneralRouterService: ProjectGeneralRouterService,
        private router: Router,
        private homepageAuthApiService: HomepageAuthApiService,
        public dialog: MatDialog,
        private navigationHelper: NavigationHelperService,
        private userStateManager: UserStateManagerService,
        private profileService: ProfileService,
        private artDirector: ArtDirectorService,
        private analyticsNotifer: AnalyticsNotifierService,
        private projectMetadataManager: ProjectMetadataManagerService
    ) {}

    ngOnInit() {
        this.getHomepageDataAsync({ videoIdeas: true, lastProjects: true, templates: true });
        this.registerToVideoIdeasUpdate();
        this.profileService.user$.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
            if (!user) {
                return;
            }
            this.personalizedDynamics = this.artDirector.extractDynamicLottieByUserBranding() ?? [];
            this.user = user;
        });

        this.projectMetadataManager.updatedProject$.pipe(takeUntil(this.onDestroy$)).subscribe((newProject) => {
            if (!newProject) return;

            this.projectMetadataManager.updateProjectsList(this.lastProjects, newProject, 3);
        });
    }

    async getHomepageDataAsync(homepageDataParams: Partial<IGetHomeDataParams>) {
        // Set initial loading states
        this.setLoadingStates(homepageDataParams, true);

        // Fetch homepage data
        const { data, error } = await handleApiCallAsync(
            this.homepageAuthApiService.getHomepageData(homepageDataParams)
        );

        if (error) {
            console.error('Error fetching homepage data:', error);
            return;
        }

        // Update homepage data and loading states
        this.updateHomepageData(data);
    }

    createNewProduction(
        params: Partial<INewProjectRequest>,
        prodType: 'videoTypes' | 'videoIdea' | 'createProjectButton'
    ) {
        if (prodType === 'videoTypes') {
            this.analyticsNotifer.notifyEvent('Homepage Recording Type', {
                type: params.recordingType,
            });
        } else if (prodType === 'videoIdea') {
            this.notifySimpleProjectCreation('Homepage Video Idea', params.prompt);
        } else if (prodType === 'createProjectButton') {
            this.notifySimpleProjectCreation('Homepage Create New Project', null);
        }
        this.navigationHelper.navigeteToWizard(params, prodType);
    }

    goToLibrary() {
        this.analyticsNotifer.notifyEvent('Homepage Show All');
        this.router.navigate(['/dashboard', { outlets: { panel: `library` } }]);
    }

    goToProject(project: IProjectInDTO) {
        this.analyticsNotifer.notifyEvent('Homepage My Projects', { projectName: project.name });
        this.projectGeneralRouterService.goToAsync(project.statuses.general, project);
    }

    goToTemplate(template: IBaseTemplate) {
        if (!template) {
            return;
        }
        /// Open dialog
        this.dialog.open(TemplateDialogComponent, {
            data: template,
            maxWidth: '95vw',
            maxHeight: '85vh',
            height: '900px',
            width: '1600px',
        });
    }

    private registerToVideoIdeasUpdate() {
        this.userStateManager.userVideoIdeasUpdate$.pipe(takeUntil(this.onDestroy$)).subscribe((newStatus) => {
            if (!newStatus) {
                return;
            }
            this.videoIdeaStatus = newStatus;
            if (newStatus === UserVideoIdeaStatusEnum.COMPLETED) {
                this.getHomepageDataAsync({ videoIdeas: true });
            }
        });
    }

    private setLoadingStates(params: Partial<IGetHomeDataParams>, isLoading: boolean) {
        this.loadingVideoIdeas = isLoading && params.videoIdeas;
        this.loadingTemplates = isLoading && params.templates;
        this.loadingProjects = isLoading && params.lastProjects;
    }

    private updateHomepageData(data: Partial<IHomepageData>) {
        if (data.lastProjects) {
            this.lastProjects = data.lastProjects;
            this.loadingProjects = false;
        }
        if (data.videoIdeas) {
            this.videoIdeas = data.videoIdeas;
            this.videoIdeaStatus = data.videoIdeaStatus;
            this.displayedVidedIdeas = this.videoIdeas.slice(0, 3); // Avoiding slice inside the template
            this.loadingVideoIdeas = false;
        }
        if (data.templates) {
            this.templates = data.templates;
            this.loadingTemplates = false;
            this.templateSliderConfig.slidesToShow = data.templates.length;
        }
    }
    notifySimpleProjectCreation(event: string, videoIdeaPrompt?: string) {
        const payload = videoIdeaPrompt ? { ideaPrompt: videoIdeaPrompt } : undefined;
        this.analyticsNotifer.notifyEvent(event, payload);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    protected readonly StudioCarouselConfig = StudioCarouselConfig;
}
