<div mat-dialog-content class="dialog-body" ngClass.lt-sm="mobile">
    <h3 class="title align-center color-shuffll">Using a link to Google Docs and Slides!</h3>
    <h4 class="align-center">
        <span
            >It's important to set the permissions <br />
            "Any one with the link can view/edit" 💪</span
        >
    </h4>
    <vg-player class="video-player border-radius-small" *ngIf="finalVideoPath">
        <vg-overlay-play vgFor="glink-video"></vg-overlay-play>

        <video
            #media
            [vgMedia]="media"
            [src]="finalVideoPath"
            id="welcome-video"
            [poster]="posterPath"
            disablePictureInPicture
            autoplay
            controls
            muted
            playsinline
            crossorigin="anonymous"></video>
    </vg-player>
</div>
<div mat-dialog-actions class="actions" ngClass.gt-xs="not-mobile">
    <!-- <button
mat-flat-button
class="payment"
color="primary"
(click)="next()"
[featureFlag]="{
features: ['AB_mustpay_free'],
featureFlagLogic: 'AND',
featureFlagMode: 'ngIf',
}">
Continue
</button> -->
    <!-- <button
mat-flat-button
mat-dialog-close
class="payment"
color="primary"
[featureFlag]="{
features: ['AB_mustpay_free'],
featureFlagLogic: 'AND',
featureFlagMode: 'ngIf',
negativeLogic: true,
}"
(click)="closeDialog()">
Continue
</button> -->
    <button mat-flat-button mat-dialog-close color="primary" MatDialogClose>Continue</button>
</div>
