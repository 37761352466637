import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    getAppendChunkToVideoLayerUrlPOST,
    getEnhanceVideoLayersPOST,
    getVideoLayerPOST,
    updateVideoLayerTrimsUrlPUT,
} from 'src/app/constants/private/urls/projects/video-layer.urls';
import { MimeTypeEnum } from 'src/app/models/defines';
import { MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { IVideoLayerInDTO } from 'src/app/models/project/take/layers/video-model';
import {
    IVideoLayerEnhanceRequestBody,
    IVideoLayerUpdateTrimsRequestBody,
    IVideoLayerUploadUrlRequestBody,
} from 'src/app/services/http-models/project/video-layer/video-layer-http-request-model';

@Injectable({
    providedIn: 'root',
})
export class VideoLayerApiService {
    constructor(private http: HttpClient) {}

    public postVideoLayer$(
        projectId: string,
        sceneId: string,
        takeId: string,
        mimetype: MimeTypeEnum,
        videoPositionId: string,
        width: number,
        height: number
    ) {
        if (!projectId || !sceneId || !takeId || !mimetype || !width || !height) {
            throw new MissingArgumentsError(
                `Could not get video layer upload url because one of the arguments is null.`
            );
        }
        const videoLayerUploadUrl = getVideoLayerPOST(projectId, sceneId, takeId);
        const body: IVideoLayerUploadUrlRequestBody = {
            mimeType: mimetype,
            height: height,
            width: width,
            videoPositionId: videoPositionId,
        };
        return this.http.post<IVideoLayerInDTO>(videoLayerUploadUrl, body);
    }

    public appendChunkToVideoLayer$(
        projectId: string,
        sceneId: string,
        takeId: string,
        videoLayerId: string,
        chunk: Blob
    ) {
        if (!projectId || !sceneId || !takeId || !videoLayerId || !chunk) {
            throw new MissingArgumentsError(
                `Could not get append chunk to video layer because one of the arguments is null.`
            );
        }

        const urlToAppendChunk = getAppendChunkToVideoLayerUrlPOST(projectId, sceneId, takeId, videoLayerId);

        const formData = new FormData();
        formData.append('chunk', chunk);
        return this.http.post<{ message: string }>(urlToAppendChunk, formData);
    }

    public updateVideoLayerTrims$(
        projectId: string,
        sceneId: string,
        takeId: string,
        videoLayerId: string,
        trimStart: number | null,
        trimEnd: number | null
    ) {
        if (!projectId || !sceneId || !takeId || !videoLayerId || (isNaN(trimStart) && isNaN(trimEnd))) {
            throw new MissingArgumentsError(
                `Could not update trim to video layer because one of the arguments is null.`
            );
        }

        const urlToUpdateTrims = updateVideoLayerTrimsUrlPUT(projectId, sceneId, takeId, videoLayerId);
        const body: IVideoLayerUpdateTrimsRequestBody = {
            trimStart: trimStart,
            trimEnd: trimEnd,
        };
        return this.http.put<boolean>(urlToUpdateTrims, body);
    }

    public enhanceVideoLayers$(
        projectId: string,
        editId: string,
        sceneId: string,
        takeId: string,
        videoLayerIds: string | string[],
        toVideoSegmentation: boolean,
        toAudioClean: boolean,
        subtitles: boolean,
        toGenerateVoiceAi: boolean
    ) {
        if (!projectId || !editId || !sceneId || !takeId || !videoLayerIds) {
            throw new MissingArgumentsError(
                `Could not get append chunk to video layer because one of the arguments is null.`
            );
        }
        if (!Array.isArray(videoLayerIds)) {
            videoLayerIds = [videoLayerIds];
        }
        const body: IVideoLayerEnhanceRequestBody = {
            videoLayerIds: videoLayerIds,
            editId: editId,
            toVideoSegmentation: toVideoSegmentation,
            toAudioClean: toAudioClean,
            toGenerateSubtitles: subtitles,
            toGenerateVoiceAi: toGenerateVoiceAi,
        };
        const urlToEnhance = getEnhanceVideoLayersPOST(projectId, sceneId, takeId);
        return this.http.post<{ message: string }>(urlToEnhance, body);
    }
}
