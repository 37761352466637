import { Injectable } from '@angular/core';
import { FeatureService, PaymentFeatureFlagEvents, PaymentVariantNames } from '../feature-service.service';
import { PlanEnum, User } from '../../models/user';
export interface IUserPaymentStatus {
    needToPay: boolean;
    needToPayNow: boolean;
    plan: PaymentVariantNames | null;
}
@Injectable({
    providedIn: 'root',
})
export class PaymentUtilsService {
    constructor(private featureService: FeatureService) {}

    async checkIfUserNeedsToPayAsync(user: User): Promise<IUserPaymentStatus> {
        const defaultValues: IUserPaymentStatus = {
            needToPayNow: false,
            needToPay: false,
            plan: null,
        };
        if (user?.plan !== PlanEnum.FREE) {
            return defaultValues;
        }

        if (!(await this.featureService.getFeatureAccessValue('should_see_paywall'))) {
            return defaultValues;
        }

        const { name: paymentVariableName } = this.featureService.getPaymentVariant();

        switch (paymentVariableName) {
            case 'trial':
                return { needToPay: true, needToPayNow: true, plan: 'trial' };
            case 'immediate':
                return {
                    needToPay: true,
                    needToPayNow: user.hasConsumedFreeOffer,
                    plan: 'immediate',
                };
            default:
                return defaultValues;
        }
    }
}
