import { Injectable } from '@angular/core';
import { VideoEditTake } from 'src/app/models/job/edit-job-schema';
import { IConvertor } from 'src/app/models/project/project-model';

import { IScene } from 'src/app/models/project/scene-model';
import { ITake, ITakeWithLottieComposed } from 'src/app/models/project/take/take-model';
import { IComposeAndPlayOrNot } from '../../../../models/job/editor-defines';
import {
    IBasicSceneTakePair,
    IEditInDTO,
    IExportEditJob,
    IExportEditOutDTO,
    IVideoEditTakeInDTO,
} from 'src/app/models/project/edit/edit-model';
import { EditStylesConverterService } from './styles/edit-styles.converter.service';
import { ExportQualityEnum } from '../../../../models/defines';

@Injectable({
    providedIn: 'root',
})
export class EditConvertorService implements IConvertor<IEditInDTO, IExportEditJob, IExportEditOutDTO> {
    constructor(private editStyleConverter: EditStylesConverterService) {}

    inToLocal(input: IEditInDTO, scenes: IScene[], baseProjectPath: string, projectId: string): IExportEditJob {
        if (!input || !scenes || !baseProjectPath) {
            console.error(`Could not convert in edit dto to local because one of the arguments is null.`);
            return;
        }

        const localVideosOnTrack: VideoEditTake[] = [];
        const copyOfVideoTakes: IVideoEditTakeInDTO[] = JSON.parse(JSON.stringify(input.videoEditTakes));
        const copyOfScenes: IScene[] = scenes.map((scene) => scene.clone(projectId));

        for (const videoScene of copyOfVideoTakes) {
            const localVideoScene = this.createLocalVideoEditTake(copyOfScenes, videoScene);
            if (!localVideoScene) continue;
            localVideosOnTrack.push(localVideoScene);
        }
        const localEditStyle = input.plugins ? this.editStyleConverter.inToLocal(input.plugins) : null;
        const localEdit: IExportEditJob = {
            id: input.id,
            aspectRatio: input.aspectRatio,
            createdAt: input.createdAt,
            exportQuality: input.exportQuality,
            exports: input.exports,
            status: input.status,
            toMaxine: input.toMaxine,
            videoEditTakes: localVideosOnTrack,
            plugins: localEditStyle,
            enhanceStatuses: input.enhanceStatuses,
            toCleanAudio: input.toCleanAudio,
            toSegmentVideo: input.toSegmentVideo,
            toAutoEnhanceDemo: input.toAutoEnhanceDemo,
        };
        return localEdit;
    }

    localToOut(client: IExportEditJob, projectId: string, selectedShots: IBasicSceneTakePair[]): IExportEditOutDTO {
        if (!client) {
            console.error(`Could not convert in edit dto to local because one of the arguments is null.`);
            return;
        }

        const outEditStyle = this.editStyleConverter.localToOut(client.plugins);

        const outEditExport: IExportEditOutDTO = {
            projectId: projectId,
            exportQuality: client.exportQuality ?? ExportQualityEnum.P1080,
            aspectRatio: client.aspectRatio,
            selectedShots: selectedShots,
            toMaxine: client.toMaxine,
            plugins: outEditStyle,
            enhanceStatuses: client.enhanceStatuses,
            toCleanAudio: client.toCleanAudio,
            toSegmentVideo: client.toSegmentVideo,
            toAutoEnhanceDemo: client.toAutoEnhanceDemo,
        };
        return outEditExport;
    }

    private findCurrentSceneAndTake(
        scenes: IScene[],
        videoScene: IVideoEditTakeInDTO
    ): { currentScene: IScene | null; currentTake: ITake | null } {
        let currentScene: IScene | null = null;
        let currentTake: ITake | null = null;

        for (const scene of scenes) {
            const take = scene.takes.find((take) => take.id === videoScene?.take.id);
            if (take && take.startTime && take.endTime) {
                currentTake = take;
                currentScene = scene;
                break;
            }
        }

        return { currentScene, currentTake };
    }

    private overrideLottieComposedConfigs(take: ITake): IComposeAndPlayOrNot {
        return JSON.parse(JSON.stringify(take.lottieComposedConfigs));
    }

    private createTakeWithComposeConfigs(
        videoScene: IVideoEditTakeInDTO,
        currentTake: ITake,
        newLottieComposedConfigs: IComposeAndPlayOrNot
    ): ITakeWithLottieComposed {
        return {
            ...videoScene.take,
            title: currentTake.title,
            lottieComposedConfigs: newLottieComposedConfigs,
        };
    }

    private createLocalVideoScene(
        takeWithComposeConfigs: ITakeWithLottieComposed,
        currentScene: IScene,
        videoScene: IVideoEditTakeInDTO
    ): VideoEditTake {
        return new VideoEditTake(takeWithComposeConfigs, {}, currentScene.id, currentScene.name, videoScene.trims);
    }

    public createLocalVideoEditTake(scenes: IScene[], videoScene: IVideoEditTakeInDTO): VideoEditTake | null {
        const { currentScene, currentTake } = this.findCurrentSceneAndTake(scenes, videoScene);

        if (!currentScene || !currentTake) {
            return null; // or continue based on your logic
        }

        // Override lottie composed configs
        const newLottieComposedConfigs = this.overrideLottieComposedConfigs(currentTake);

        // Create new take with composed configs
        const takeWithComposeConfigs = this.createTakeWithComposeConfigs(
            videoScene,
            currentTake,
            newLottieComposedConfigs
        );

        // Create the local video scene
        return this.createLocalVideoScene(takeWithComposeConfigs, currentScene, videoScene);
    }
}
