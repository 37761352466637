import { Injectable } from '@angular/core';
import { SocketEventWrapperService } from '../socket-event-wrapper.service';
import {
    IProjectMainClientToServer,
    IProjectMainServerToClientEvents,
} from '../../../models/socket-events/project/socket-project-main-events';

@Injectable()
export class ProjectMainEventsService {
    projectId: string = '';
    constructor(
        private socketEventWrapper: SocketEventWrapperService<
            IProjectMainClientToServer,
            IProjectMainServerToClientEvents
        >
    ) {
        this.socketEventWrapper.isSocketReConnectedInWrapper$.subscribe(() => {
            this.joinProjectRoom(this.projectId);
        });
    }

    joinProjectRoom(projectId: string) {
        if (!projectId) return;
        this.projectId = projectId;
        this.socketEventWrapper.emit('joinProjectRoom', {
            projectId: projectId,
        });
    }

    disconnectFromRoom(projectId: string) {
        this.socketEventWrapper.emit('disconnectFromProjectRoom', {
            projectId: projectId,
        });
    }
}
