import { Component } from '@angular/core';
import { AnalyticsNotifierService } from '../../../../../../../services/utils/analytics-notifier.service';
import { ConfigurationService } from '../../../../../../../services/configuration.service';

@Component({
    selector: 'app-google-link-guide',
    templateUrl: './google-link-guide.component.html',
    styleUrl: './google-link-guide.component.scss',
})
export class GoogleLinkGuideComponent {
    VIDEO_SUB_DIR = '/files/platform/google-links-guide-video';
    hlsPath;
    mp4Path;
    finalVideoPath;
    posterPath;

    constructor(
        private analyticsNotfier: AnalyticsNotifierService,
        private config: ConfigurationService
    ) {
        this.finalVideoPath = config.baseCdnUrl + this.VIDEO_SUB_DIR + '/how-to.mp4';

        // plansService.getUnifiedPlan();
        // if (this.data.showVideo === false) {
        //   this.showPricing = true;
        // } else {
        //   this.showPricing = false;
        // }
    }

    ngOnInit(): void {
        // if (this.isHLSSupported()) {
        //     this.finalVideoPath = this.hlsPath;
        //     console.log('playing hls video');
        // } else {
        //     this.finalVideoPath = this.mp4Path;
        //     console.log('playing mp4 video');
        // }

        this.analyticsNotfier.pushTag({ event: 'Google Link Guide Opened' });
    }

    // isHLSSupported(): boolean {
    //     const video = document.createElement('video');
    //     return Boolean(video.canPlayType('application/vnd.apple.mpegURL') || video.canPlayType('audio/mpegurl'));
    // }
}
