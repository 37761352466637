import { Injectable } from '@angular/core';
import { HOMEPAGE_BASE_URL } from '../../../constants/private/urls/projects/homepage.urls';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IHomepageData } from 'src/app/models/homepage/homepage-defines';
export interface IGetHomeDataParams {
    templates: boolean;
    videoIdeas: boolean;
    lastProjects: boolean;
}
@Injectable({
    providedIn: 'root',
})
export class HomepageAuthApiService {
    constructor(private http: HttpClient) {}

    public getHomepageData(params?: Partial<IGetHomeDataParams>) {
        let httpParams = new HttpParams();

        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                if (value !== undefined) {
                    httpParams = httpParams.set(key, String(value));
                }
            });
        }

        return this.http.get<IHomepageData>(HOMEPAGE_BASE_URL, { params: httpParams });
    }
}
