import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IStorytellingTechnique } from 'src/app/models/defines';

export interface IStorytellingChooserData {
    selectedTechnique: string;
    storytellingTechniqueList: IStorytellingTechnique[];
}

@Component({
    selector: 'app-storytelling-technique-chooser',
    templateUrl: './storytelling-chooser.component.html',
    styleUrl: './storytelling-chooser.component.scss',
})
export class StorytellingChooserComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: IStorytellingChooserData) {}
}
