import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IBaseTemplate } from 'src/app/models/homepage/homepage-defines';
import { Subject } from 'rxjs';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';

@Component({
    selector: 'template-card',
    templateUrl: './template-card.component.html',
    styleUrl: './template-card.component.scss',
    animations: [
        fadeInOnEnterAnimation({ duration: 250, delay: 0 }),
        fadeOutOnLeaveAnimation({ duration: 250, delay: 0 }),
    ],
})
export class TemplateCardComponent implements OnInit {
    @Input() template: IBaseTemplate;
    @Output('useTemplate') useTemplate = new Subject<IBaseTemplate>();
    @ViewChild('media', { static: false }) media!: ElementRef<HTMLVideoElement>;

    baseCdnUrl: string;
    isPlaying = false;

    constructor(private config: ConfigurationService) {}

    ngOnInit(): void {
        this.baseCdnUrl = this.config.baseCdnUrl + this.template?.basePath ?? '';
    }

    vgApi!: VgApiService;

    onHover(vgPlayer: any) {
        const video = this.media.nativeElement;
        console.log(video);
        video.play();
        this.isPlaying = true;
    }

    onLeave(vgPlayer: any) {
        this.vgApi = vgPlayer.api;
        // Rewind to the begining
        this.vgApi.seekTime(0);

        const video = this.media.nativeElement;
        video.currentTime = 0;
        video.pause();
        this.isPlaying = false;
    }

    onMediaPlay(): void {
        this.isPlaying = true;
    }

    onMediaPause(): void {
        this.isPlaying = false;
    }
}
