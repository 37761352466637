import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { IBaseTemplate, ITemplateScene } from '../../../../../models/homepage/homepage-defines';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { NavigationHelperService } from 'src/app/services/utils/navigation-helper.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { AnalyticsNotifierService } from '../../../../../services/utils/analytics-notifier.service';

@Component({
    selector: 'template-dialog',
    templateUrl: './template-dialog.component.html',
    styleUrl: './template-dialog.component.scss',
    animations: [
        trigger('flipInXOnEnter', [
            transition(':enter', [
                style({ transform: 'rotateX(90deg)', opacity: 0 }),
                animate('0.3s ease', style({ transform: 'rotateX(0)', opacity: 1 })),
            ]),
        ]),
        trigger('bounceOutOnLeave', [
            transition(':leave', [animate('0.3s ease', style({ transform: 'scale(0.5)', opacity: 0 }))]),
        ]),
    ],
})
export class TemplateDialogComponent implements OnInit {
    @ViewChild('media', { static: false }) media!: ElementRef<HTMLVideoElement>;

    template: IBaseTemplate;
    selectedScene: ITemplateScene;
    baseUrl: string;

    playOrPause = {
        isPlaying: false,
    };
    scenesSliderConfig = {
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        dots: false,
        variableWidth: true,

        // responsive: [
        //   {
        //     breakpoint: 1300,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        //   {
        //     breakpoint: 800,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        //   {
        //     breakpoint: 600,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        // ],
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IBaseTemplate,
        public dialogRef: MatDialogRef<TemplateDialogComponent>,
        private config: ConfigurationService,
        private navigationHelperService: NavigationHelperService,
        private analyticsNotifierService: AnalyticsNotifierService
    ) {
        this.template = data;
        this.selectedScene = this.template?.scenes[0];
        console.log(data);
        this.baseUrl = config.baseCdnUrl + this.template?.basePath ?? '';
    }

    ngOnInit(): void {}

    useTemplate() {
        this.analyticsNotifierService.notifyEvent(`Homepage Template`, { templateName: this.template.name });
        this.navigationHelperService.navigeteToWizard({ templateId: this.template._id }, 'template');
        this.dialogRef?.close();
    }

    previewScene(scene: ITemplateScene) {
        this.selectedScene = scene;
        // this.dialogRef.close(scene);
    }

    requestPlayOrPause(play: boolean): void {
        const video = this.media.nativeElement;

        if (play) {
            video.play();
        } else {
            video.pause();
        }
        this.playOrPause.isPlaying = play;
    }

    onMediaPlay(): void {
        this.playOrPause.isPlaying = true;
    }

    onMediaPause(): void {
        this.playOrPause.isPlaying = false;
    }
}
