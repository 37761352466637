<mat-progress-bar mode="indeterminate" [class.hideme]="!loadingData"></mat-progress-bar>
<ng-template #myProjectsLoader>
    <div class="spinner-holder">
        <loading-indicator></loading-indicator>
    </div>
</ng-template>
<ng-container *ngIf="!loadingData; else myProjectsLoader">
    <div class="library-container dashboard-page padded" [ngClass.lt-sm]="'mobile'">
        <div class="header row-spacebetween" *ngIf="!loadingData && projects?.length > 0">
            <h2 class="header__title">
                <app-sidenav-toggle title="My Projects"></app-sidenav-toggle>
            </h2>

            <div class="header__actions">
                <button mat-raised-button color="primary" (click)="createNewProduction()" fxHide fxShow.gt-sm>
                    <mat-icon>add</mat-icon>
                    <span>New Project</span>
                </button>

                <button mat-mini-fab color="primary" (click)="createNewProduction()" fxShow fxHide.gt-sm>
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>

        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="left" *ngIf="!loadingData && projects?.length > 0">
            <mat-tab
                *ngFor="let tab of tabs"
                [label]="tab | titlecase"
                [disabled]="loadingData || !projects || projects.length === 0">
                <div [hidden]="this.isDuplicateOrDeleteLoader">
                    <ng-container *ngIf="tab === 'All'; else categoryBlock">
                        <div class="project-list">
                            <div class="project-card-wrap" *ngFor="let project of projects">
                                <div class="project-card-header">
                                    <!-- 3-dots menu -->
                                    <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button
                                            [disabled]="toDisableDuplicate"
                                            mat-menu-item
                                            (click)="duplicateProject(project.id)">
                                            <mat-icon>content_copy</mat-icon>
                                            <span>Duplicate</span>
                                        </button>
                                        <button mat-menu-item (click)="deleteProject(project.id)">
                                            <mat-icon>delete</mat-icon>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </div>

                                <!-- Project card -->
                                <project-card [project]="project" (click)="goToProject(project)"></project-card>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="this.isDuplicateOrDeleteLoader">
                    <full-screen-loading [text]="'Loading Projects'"></full-screen-loading>
                </div>

                <ng-template #categoryBlock>
                    <div *ngIf="(projectsInStatuses[tab] || []).length === 0" class="no-post-productions-container">
                        <!-- You might want to adjust this block for specific virtual empty states -->
                        <h4 class="align-center">No projects in {{ tab }} stage.</h4>
                    </div>
                    <div class="project-list">
                        <div class="project-card-wrap" *ngFor="let project of projectsInStatuses[tab] || []">
                            <project-card [project]="project" (click)="goToProject(project)"></project-card>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

        <!--  <app-video-paymentComponent-view-->
        <!--    *ngIf="selectedGroupByOption === groupByEnum.NONE"-->
        <!--    [historyStreams]="episodes"></app-video-paymentComponent-view>-->

        <!--  <div class="series-list">-->
        <!--    <div *ngFor="let series of seriesList" s class="series-list__series">-->
        <!--      <div-->
        <!--        *ngIf="series.episodes.length > 0"-->
        <!--        class="series-list__series__title">-->
        <!--        <span>{{ series.name }}</span>-->
        <!--        <button-->
        <!--          class="add-to-series-btn"-->
        <!--          (click)="createNewProduction(series.id)"-->
        <!--          mat-mini-fab-->
        <!--          color="primary">-->
        <!--          <mat-icon>add</mat-icon>-->
        <!--        </button>-->
        <!--      </div>-->
        <!--      &lt;!&ndash; <carousel-->
        <!--        div-->
        <!--        class="carousel"-->
        <!--        *ngIf="-->
        <!--          selectedGroupByOption === groupByEnum.SERIES &&-->
        <!--          series.episodes.length !== 0-->
        <!--        "-->
        <!--        [arrowsOutside]="series.episodes.length > 2 ? true : false"-->
        <!--        [cellsToShow]="3"-->
        <!--        [cellsToScroll]="1"-->
        <!--        [cellWidth]="400"-->
        <!--        [width]="1200"-->
        <!--        [height]="350">-->
        <!--        <div class="carousel-cell" *ngFor="let episode of series.episodes">-->
        <!--           <img [src]="baseCdn + episode.format.icon" /> &ndash;&gt;-->
        <!--      &lt;!&ndash; <app-episode-paymentComponent-view [episode]="episode"></app-episode-paymentComponent-view>-->
        <!--        </div> &ndash;&gt;-->
        <!--      &lt;!&ndash; </carousel> &ndash;&gt;-->
        <!--    </div>-->
        <!--  </div>-->

        <new-project *ngIf="!loadingData && projects?.length === 0"></new-project>
    </div>
</ng-container>
