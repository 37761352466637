import { Injectable, OnDestroy } from '@angular/core';
import { UnleashClient } from 'unleash-proxy-client';
import { BehaviorSubject, EMPTY, filter, Observable, of, Subscription, take, takeUntil, timer } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { ProfileService } from './show/profile.service';
import { UserService } from './api/auth/user.service';
import { LOCAL_STORAGE_INITIAL_URL_PARAMS_KEY } from '../constants/shared/local-storage.constants';
export type PaymentVariantNames = 'trial' | 'immediate';
export type PaymentFeatureFlagEvents = 'should_see_paywall';
export type FeatureFlashEvents =
    | 'shuffllTeamFeature'
    | 'Editroom_access'
    | 'wizard_videotype_selection'
    | 'wizard_confirm_finish'
    | PaymentFeatureFlagEvents;

@Injectable({
    providedIn: 'root',
})
export class FeatureService implements OnDestroy {
    private unleash: UnleashClient;
    private featureCache: Map<string, BehaviorSubject<boolean>> = new Map();
    private userSubscription: Subscription;
    private featureFlagChange$ = new BehaviorSubject<void>(null);

    constructor(
        private config: ConfigurationService,
        private profileService: ProfileService,
        private userService: UserService
    ) {
        this.unleash = new UnleashClient(config.unleashConfig);
        this.subscribeToUserChanges();
        this.unleash.start();
    }

    /// using or string because we have in our html something dynamic related to item + 'Feature'
    hasFeatureAccess(feature: FeatureFlashEvents | string): Observable<boolean> {
        if (!this.featureCache.has(feature)) {
            const subject = new BehaviorSubject<boolean>(null);
            this.featureCache.set(feature, subject);
            this.updateFeatureFlag(feature);
        }
        return this.featureCache.get(feature).asObservable();
    }

    getFeatureAccessValue(feature: FeatureFlashEvents | string) {
        return new Promise<boolean>((resolve, reject) =>
            this.hasFeatureAccess(feature)
                .pipe(
                    filter((value) => value !== null),
                    take(1)
                )
                .subscribe((value) => {
                    return resolve(value);
                })
        );
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    private subscribeToUserChanges(): void {
        this.userSubscription = this.profileService.user$
            .pipe(
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
                catchError((error) => {
                    console.error('Error in user subscription:', error);
                    return EMPTY;
                })
            )
            .subscribe(async (user) => {
                if (user) {
                    try {
                        const isMobile = this.userService.isMobileUser();
                        let userDevice;
                        if (isMobile === true) {
                            userDevice = 'mobile';
                        } else {
                            userDevice = 'desktop';
                        }
                        const userBrowser = this.profileService.userBrowser;

                        let properties = {
                            'user._id': user._id,
                            'user.email': user.email,
                            'user.plan': user.plan,
                            'user.device': userDevice ? userDevice : 'unknown',
                            'user.browser': userBrowser ? userBrowser : 'unknown',
                        };

                        const urlParams = this.getInitialUrlParams();
                        if (urlParams) {
                            properties = { ...properties, ...urlParams };
                        }

                        // Get invite code

                        // Get all initial URl Params

                        this.unleash.updateContext({
                            properties: properties,
                            userId: user.email,
                        });
                        this.invalidateCache();
                    } catch (error) {
                        console.error('Error updating Unleash context:', error);
                    }
                } else {
                    this.unleash.updateContext({});
                    this.invalidateCache();
                }
            });
    }

    private updateFeatureFlag(feature: string, forceUpdate = false): void {
        timer(0, this.config.unleashConfig.cacheInterval)
            .pipe(
                switchMap(() => this.checkFeature(feature)),
                catchError((error) => {
                    console.error(`Error checking feature ${feature}:`, error);
                    return of(false);
                })
            )
            .subscribe((isEnabled) => {
                if (this.featureCache.has(feature)) {
                    this.featureCache.get(feature).next(isEnabled);
                }
            });
    }

    public getVariant(flagName: FeatureFlashEvents) {
        return this.unleash.isEnabled(flagName);
    }

    public getPaymentVariant() {
        const paymentFlag: PaymentFeatureFlagEvents = 'should_see_paywall';
        const variant = this.unleash.getVariant(paymentFlag);
        return {
            name: variant.name as PaymentVariantNames,
        };
    }

    private checkFeature(feature: string): Observable<boolean> {
        try {
            const isEnabled = this.unleash.isEnabled(feature);
            return of(isEnabled);
        } catch (error) {
            console.error(`Error checking feature ${feature}:`, error);
            return of(false);
        }
    }

    get featureFlagChange(): Observable<void> {
        return this.featureFlagChange$.asObservable();
    }

    private invalidateCache() {
        this.featureCache.forEach((_, feature) => this.forceUpdateFeatureFlag(feature));
        this.featureFlagChange$.next();
    }

    getInitialUrlParams() {
        try {
            /// Get all initial URl Params from local storage
            const localStorageInitialUrlParams = localStorage.getItem(LOCAL_STORAGE_INITIAL_URL_PARAMS_KEY);

            if (localStorageInitialUrlParams) {
                const urlParams = JSON.parse(localStorageInitialUrlParams);
                const urlParamContext = {};
                // Constract an object in this form:
                // urlparam.key = urlparam.value
                for (const key in urlParams) {
                    const value = urlParams[key];
                    if (value) {
                        urlParamContext['urlParams.' + key] = value;
                    }
                }
                return urlParamContext;
            }
            return null;
        } catch (ex) {
            console.error(ex);
            return null;
        }
    }

    /**
     *  Forces an update of a feature flag from unleash.
     * @param feature
     * @private
     */
    private forceUpdateFeatureFlag(feature: string) {
        if (!this.featureCache.has(feature)) {
            this.featureCache.set(feature, new BehaviorSubject<boolean>(false));
        }
        this.featureCache.get(feature).next(this.unleash.isEnabled(feature));
    }
}
