<h2 mat-dialog-title>AI Image Generation</h2>
<div mat-dialog-content>
    <div style="margin-bottom: 15px">Write a promptChild to generate an image</div>
    <mat-form-field appearance="fill" class="field">
        <input
            matInput
            type="text"
            #promptInput="ngModel"
            [(ngModel)]="prompt"
            name="prompt"
            minlength="3"
            required
            placeholder="Describe your image" />

        <mat-error *ngIf="promptInput.errors?.required || promptInput.errors?.minlength"
            >Write a promptChild to generate image
        </mat-error>
        <mat-error *ngIf="promptInput.errors?.pattern">Prompt must be at least 3 characters</mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null" cdkFocusInitial>Cancel</button>
    <button mat-button mat-raised-button color="primary" [disabled]="!promptInput.valid" [mat-dialog-close]="prompt">
        Generate
    </button>
</div>
