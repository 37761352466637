import { Injectable } from '@angular/core';
import { SocketEventWrapperService } from '../../socket-event-wrapper.service';
import {
    IUserProjectClientToServerEvents,
    IUserProjectServerToClientEvents,
} from '../../../../models/socket-events/user/user-project/user-project-events';

@Injectable()
export class UserProjectEventsService {
    constructor(
        private socketEventWrapper: SocketEventWrapperService<
            IUserProjectClientToServerEvents,
            IUserProjectServerToClientEvents
        >
    ) {}

    onProjectUpdated() {
        return this.socketEventWrapper.fromEvent('onProjectUpdated');
    }
}
