import { Injectable } from '@angular/core';
import { SceneApiService } from '../../../api/auth/projects/scene-api.service';
import { INewChosenTakeSocketData } from '../../../../models/socket-events/project/scene/socket-scene-events';
import { CompositionTypeEnum } from '../../../../models/defines';
import { catchErrorTypedAsync } from '../../../functions-helper.service';
import { handleApiCallAsync } from '../../../../utils/general-functions';
import { AnalyticsNotifierService } from '../../../utils/analytics-notifier.service';

@Injectable()
export class SceneActionService {
    constructor(
        private sceneApi: SceneApiService,
        private analyticsNotifierService: AnalyticsNotifierService
    ) {}

    updateSceneSelectedTakeAsync(projectId: string, sceneId: string, newChosenTakeId: string) {
        return new Promise<INewChosenTakeSocketData>((resolve, reject) => {
            this.sceneApi.updateChosenTake$(projectId, sceneId, newChosenTakeId).subscribe({
                next: (payload) => {
                    return resolve(payload);
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    }

    updateSceneCompositionAsync(projectId: string, sceneId: string, composition: string | CompositionTypeEnum) {
        this.analyticsNotifierService.notifyEvent(`Replace Scene`, { projectId });
        return handleApiCallAsync(this.sceneApi.updateSceneComposition$(projectId, sceneId, composition));
    }

    getSceneAsync(projectId: string, sceneId: string) {
        return handleApiCallAsync(this.sceneApi.getScene$(projectId, sceneId));
    }
}
