<div class="card video-idea-card row-spacebetween big-gapped" [class.no-hover-effects]="dummy" [class.shimmer]="dummy">
    <ng-container *ngIf="!dummy; else dummyTemplate">
        <div class="video-idea-card__icon" *ngIf="videoCardIconLayout && this.extendedDynamicValues">
            <dynamic-lottie
                [config]="{
                    layout: this.videoCardIconLayout,
                    dynamicLottieChanges: this.extendedDynamicValues,
                    basePath: videoIdea?.designGroup?.basePath ?? '',
                }">
            </dynamic-lottie>
        </div>
        <div class="video-idea-card__info" *ngIf="videoIdea">
            <!--        <div class="video-idea-card__info__title"></div>-->
            <div class="video-idea-card__info__title">
                {{ videoIdea.prompt }}
            </div>
        </div>
    </ng-container>

    <ng-template #dummyTemplate>
        <div class="video-idea-card__icon low-opacity"></div>
        <div class="video-idea-card__info">
            <div class="video-idea-card__info__prompt">
                <div class="shimmer-line low-opacity"></div>
                <div class="shimmer-line low-opacity"></div>
            </div>
        </div>
    </ng-template>
</div>
