import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AnimationItem } from 'lottie-web';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { LoadingWithSwagComponent } from '../../../../../../components/minor/loading-with-swag/loading-with-swag.component';
import { CreativeAuthApiService } from '../../../../../../services/api/auth/creative-auth-api.service';
import { fadeInOnEnterAnimation } from 'angular-animations';
import {
    GoalType,
    IStorytellingTechnique,
    ITagAndSuggestions,
    IToneOfVoice,
    IVideoTag,
    ProjectRecordingTypeEnum,
    ProjectStatusEnum,
    PromptTypeEnum,
} from 'src/app/models/defines';
import { catchError, finalize, retry } from 'rxjs/operators';
import { Subject, take, takeUntil, throwError } from 'rxjs';
import { WalkthroughPanelService } from 'src/app/services/walkthrough-panel.service';
import { TaskTypeEnum } from 'src/app/models/walkthrough';
import {
    AnalyticsNotifierService,
    AnalyticsNotifierServices,
} from '../../../../../../services/utils/analytics-notifier.service';
import {
    INewProjectRequest,
    IPromptValidationRequest,
    IWizardParams,
    ProjectAuthApiService,
} from '../../../../../../services/api/auth/projects/project-auth-api.service';
import { IBasicProjectData, IProjectInDTO } from 'src/app/models/project/project-model';
import { ProfileService } from 'src/app/services/show/profile.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { IDesignGroup } from 'src/app/models/design.model';
import { ProjectGeneralRouterService } from '../../../../../../services/project/routes/project-general-router.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { WizardManagerService } from '../../../../../../services/state-management/wizard/wizard-manager.service';
import { FeatureService } from '../../../../../../services/feature-service.service';
import { PromptComponent } from '../prompt/prompt.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarActionEnum, SnackBarService } from '../../../../../../services/utils/snack-bar.service';
import { GoogleLinkGuideComponent } from '../prompt/google-link-guide/google-link-guide.component';
import { MatDialog } from '@angular/material/dialog';
import { INewProjectWithPrompt } from '../../../../../../services/http-models/project/project-http-response-model';
import { ActivatedRoute } from '@angular/router';
import { UserStateManagerService } from '../../../../../../services/state-management/user/user-state-manager.service';
import {
    ConfirmationModalComponent,
    IConfirmationModalData,
} from '../../../../../../components/dialogs/confirmation-modal/confirmation-modal.component';

interface Goal {
    type: GoalType;
    title: string;
    description: string;
    icon: string;
    animation?: AnimationItem;
}

export enum PromptValidationErrorMessagesEnum {
    GDOCS_NOT_EDIT_OR_VIEW_LINK = 'The Google Docs link is not editable or viewable.',
    URL_NOT_REACHABLE = 'The URL provided is not reachable.',
    GDOCS_NO_PERMISSION = 'You do not have permission to access this Google Doc.',
    EXPECTED_LINK_BUT_NON_FOUND = 'A link was expected but not found in the input.',
    INVALID_CONTENT_LENGTH = 'The content length is invalid.',
}

@Component({
    selector: 'project-wizard',
    templateUrl: './project-wizard.component.html',
    styleUrls: ['./project-wizard.component.scss'],
    animations: [fadeInOnEnterAnimation({ duration: 500, delay: 100 })],
})
export class ProjectWizardComponent implements OnInit, OnDestroy {
    @ViewChild('loadingWithSwagFinal', { static: true })
    suggestions: string[] = null;
    isLoadingPromptSuggestions = true;
    onDestroy$ = new Subject<boolean>();
    loadingWithSwagFinal: LoadingWithSwagComponent;
    taskType: TaskTypeEnum = TaskTypeEnum.PRODUCTION;
    project: IProjectInDTO;
    baseCdnUrl: string;

    finalLoadingSteps = ['Writing your script', 'Customizing Your Storyline 🚀', 'Taking You To The Project 🎞️'];

    ///'Animating Scenes ðŸŽ¬',

    selectedDesignGroup: IDesignGroup;
    selectedRecordingType: ProjectRecordingTypeEnum;
    allPromptSuggestions: ITagAndSuggestions[];

    @ViewChild('finalLoading') finalLoading: MatStep;

    @ViewChild('stepper') stepper: MatStepper;

    @ViewChild('prompt') promptChild: PromptComponent;

    subjectFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
    });

    isLinear = false;
    suggestionsReady = false;

    type = 'hi';
    videoTags: IVideoTag[] = [];
    promptTypesList: PromptTypeEnum[] = [PromptTypeEnum.SIMPLE_PROMPT, PromptTypeEnum.LONG_SCRIPT, PromptTypeEnum.LINK];
    PromptTypeEnum = PromptTypeEnum;
    selectedPromptType: PromptTypeEnum = null;
    selectedVideoTag: IVideoTag = null;
    suggestedDesigns: IDesignGroup[] = [];
    privateDesigns: IDesignGroup[] = [];
    isLoadingDesigns = false;
    toDisplaySuggestions = true;
    toDisplayVideoTypeStepFlag: boolean;
    toDisplayVideoTypeStep = true;
    promptButtonLoading: boolean;
    showLinkGuide = false;
    toneOfVoiceList: IToneOfVoice[];
    storyTellingTeqniquesList: IStorytellingTechnique[];
    injectPrompt: string;
    selectedTemplateId: string;
    selectedDesignGroupId: string;
    projectCreatedFrom: 'videoTypes' | 'videoIdea' | 'createProjectButton' | 'template';
    notifyCreditDialogOpened = 'Credit-Dialog';
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private creativeApi: CreativeAuthApiService,
        private snackbarService: SnackBarService,
        private walkthroughPanelService: WalkthroughPanelService,
        private gtmService: AnalyticsNotifierService,
        public projectAuthApiService: ProjectAuthApiService,
        public profileService: ProfileService,
        private config: ConfigurationService,
        private projectGeneralRouting: ProjectGeneralRouterService,
        private wizardManager: WizardManagerService,
        private featureService: FeatureService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private userStateManager: UserStateManagerService
    ) {
        this.baseCdnUrl = this.config.baseCdnUrl;
    }

    changeVideoTag = (selectedTag: IVideoTag) => {
        this.selectedVideoTag = selectedTag;
        this.suggestions = this.allPromptSuggestions.find((x) => {
            return x.tag.id === selectedTag.id;
        })?.suggestions;
    };

    changePromptType = (selectedType: PromptTypeEnum) => {
        this.selectedPromptType = selectedType;

        this.suggestionsReady = this.selectedPromptType === PromptTypeEnum.SIMPLE_PROMPT;
        this.showLinkGuide = this.selectedPromptType === PromptTypeEnum.LINK;
    };

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    recordingTypeSelected(recordingType: ProjectRecordingTypeEnum) {
        if (!recordingType) return;

        console.log(recordingType);
        this.selectedRecordingType = recordingType;
        this.nextStep();
    }

    formatSelected(format: IDesignGroup) {
        console.log(format);
        this.selectedDesignGroup = format;
        this.nextStep();
    }

    subscribeToWizardManager() {
        this.wizardManager.suggestedDesigns$.pipe(takeUntil(this.onDestroy$)).subscribe((suggestedDesigns) => {
            if (!suggestedDesigns) return;
            this.suggestedDesigns = suggestedDesigns;
        });
        this.wizardManager.privateDesigns$.pipe(takeUntil(this.onDestroy$)).subscribe((privateDesigns) => {
            if (!privateDesigns) return;
            this.privateDesigns = privateDesigns;
        });
        this.wizardManager.isLoadingDesigns$.pipe(takeUntil(this.onDestroy$)).subscribe((isLoadingDesigns) => {
            this.isLoadingDesigns = isLoadingDesigns;
        });
        this.wizardManager.allPromptSuggestions$.pipe(takeUntil(this.onDestroy$)).subscribe((promptSuggestions) => {
            if (!promptSuggestions) return;
            this.allPromptSuggestions = promptSuggestions;
        });
        this.wizardManager.videoTags$.pipe(takeUntil(this.onDestroy$)).subscribe((videoTags) => {
            if (!videoTags) return;
            this.videoTags = videoTags;

            if (this.videoTags.length > 0) {
                // Temporary
                this.selectedVideoTag = this.videoTags.find((x) => x.title === 'Explainer Video') || this.videoTags[0];
                this.changeVideoTag(this.selectedVideoTag);
            }
        });

        this.wizardManager.toneOfVoiceList$.pipe(takeUntil(this.onDestroy$)).subscribe((toneOfVoiceList) => {
            if (!toneOfVoiceList) return;
            this.toneOfVoiceList = toneOfVoiceList;
        });

        // storytelling techniques
        this.wizardManager.storyTellingTeqniquesList$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((storyTellingTeqniquesList) => {
                if (!storyTellingTeqniquesList) return;

                const noTechnique: IStorytellingTechnique = {
                    technique: null,
                    whyItWorks: null,
                    description: 'Write a script that does not involve any storytelling technique.',
                    example: null,
                };

                this.storyTellingTeqniquesList = [noTechnique, ...storyTellingTeqniquesList];
            });

        this.wizardManager.isLoadingPromptSuggestions$.pipe(takeUntil(this.onDestroy$)).subscribe((isLoadingPrompt) => {
            this.isLoadingPromptSuggestions = isLoadingPrompt;
        });
    }

    generateNewSuggestionDesigns(event: boolean) {
        if (event === true) {
            this.wizardManager.generateDesignsSuggestions();
        }
    }

    createLoadingPromise(loader, delayBetweenSteps) {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (loader) {
                    if (!loader.jumpStep()) {
                        clearInterval(interval);
                        resolve(true);
                    }
                }
            }, delayBetweenSteps);
        });
    }

    async finalizeFormatAndCopy() {
        const delayForPromise = 2800;

        const loadingPromise = this.createLoadingPromise(this.loadingWithSwagFinal, delayForPromise);

        const paymentType = this.userStateManager.getNeedToPayValue().plan;
        const designGroupId = this.selectedDesignGroupId || this.selectedDesignGroup?.id;
        const projectCreationRequest: INewProjectRequest = {
            prompt: this.promptChild?.prompt,
            toneOfVoice: this.promptChild?.toneOfVoice,
            videoLength: this.promptChild?.selectedVideoLength?.value,
            storyTellingTechnique: this.promptChild?.storytellingTechnique,
            videoTagId: this.selectedVideoTag.id,
            promptType: this.selectedPromptType,
            designGroupId: designGroupId,
            recordingType: this.selectedRecordingType ? this.selectedRecordingType : ProjectRecordingTypeEnum.CAMERA,
            templateId: this.selectedTemplateId,
        };
        this.gtmService.notifyEvent('Finished Wizard', {
            videoTag: this.selectedVideoTag?.title ?? '',
            // videoType: this.selectedRecordingType ?? '',
            designGroup: this.selectedDesignGroup?.name ?? '',
            prodType: this.projectCreatedFrom ?? '',
            ...projectCreationRequest,
        });
        this.projectAuthApiService
            .createNewProject$(projectCreationRequest)
            .pipe(
                retry({
                    count: this.creativeApi.MAX_RETRY_ATTEMPTS,
                    delay: (error, retryCount) => this.creativeApi.shouldRetry(error, retryCount),
                }),
                takeUntil(this.onDestroy$),
                catchError((error) => {
                    // Handle errors or propagate them further
                    console.error('Error:', error);
                    return throwError(() => error);
                }),
                finalize(async () => {
                    const data = await Promise.all([loadingPromise]);
                })
            )
            .subscribe({
                next: async (newProjectResponse: INewProjectWithPrompt) => {
                    const user = this.profileService.user$.getValue();
                    const dataToNotify = {
                        name: user.name,
                        email: user.email,
                        validPrompt: newProjectResponse?.validPrompt ?? false,
                        prompt: this.promptChild?.prompt,
                    };
                    this.profileService.updateUserOffer(newProjectResponse.hasConsumedFreeOffer);
                    if (newProjectResponse?.validPrompt) {
                        this.gtmService.notifyEvent('Good Prompt', dataToNotify);
                    } else {
                        this.gtmService.notifyEvent('Good Prompt', dataToNotify, [AnalyticsNotifierServices.MIXPANEL]);
                    }
                    if (!newProjectResponse.project) {
                        return;
                    }
                    this.jumpToProject(newProjectResponse.project);
                },
                error: (error) => {
                    // This means that the default values will be applied to the scenes
                    console.error(`Could not generate topics, error: ${error}`);
                },
            });
    }

    jumpToProject(project: IBasicProjectData) {
        if (!project) {
            return;
        }
        this.projectGeneralRouting.goToAsync(ProjectStatusEnum.PLANNING, project);
    }

    ngOnInit(): void {
        /// Read URL params
        this.route.queryParams.subscribe((params: IWizardParams) => {
            const urlParams: IWizardParams = params;
            this.processUrlParams(urlParams);
        });

        // Check if feature flag to display the option to choose video type is turn on
        this.featureService
            .hasFeatureAccess('wizard_videotype_selection')
            .pipe(take(2))
            .subscribe({
                next: (hasAccess) => {
                    this.toDisplayVideoTypeStepFlag = hasAccess;
                    if (hasAccess === true) {
                        this.toDisplayVideoTypeStepFlag = hasAccess;
                    }
                },
                error: (error) => {
                    console.warn(`NO FEATURE FLAG DATA`);
                },
            });
        this.subscribeToWizardManager();
    }

    processUrlParams(urlParams: IWizardParams) {
        if (urlParams?.prodType) {
            this.projectCreatedFrom = urlParams.prodType;
        }
        if (!urlParams) {
            return;
        }

        if (urlParams.prompt) {
            this.injectPrompt = urlParams.prompt;
            this.selectedPromptType = PromptTypeEnum.SIMPLE_PROMPT;
            this.toDisplaySuggestions = false;
        }
        if (urlParams.recordingType) {
            this.selectedRecordingType = urlParams.recordingType;
            // remove the step completly
            this.toDisplayVideoTypeStep = false;
        }
        if (urlParams.templateId) {
            this.selectedTemplateId = urlParams.templateId;
            this.selectedPromptType = PromptTypeEnum.SIMPLE_PROMPT;
            this.selectedRecordingType = ProjectRecordingTypeEnum.CAMERA;
            this.toDisplayVideoTypeStep = false;
            this.toDisplaySuggestions = false;
        }
        if (urlParams.designGroupId) {
            this.selectedDesignGroupId = urlParams.designGroupId;
        }
    }

    async stepChanged(event: StepperSelectionEvent) {
        if (event?.selectedStep === this.finalLoading) {
            console.log('final step');
            const paymentStatus = this.userStateManager.getNeedToPayValue();
            const didOpenCreditDialog = localStorage.getItem(this.notifyCreditDialogOpened);
            if (paymentStatus.needToPay && !paymentStatus.needToPayNow && !didOpenCreditDialog) {
                if (await this.featureService.getFeatureAccessValue('wizard_confirm_finish')) {
                    console.log(`need to see popup`);
                } else {
                    console.log('dont need to see popup');
                }
                localStorage.setItem(this.notifyCreditDialogOpened, 'true');
                const toContinue = await this.createConfirmFreeProjectDialog();
                if (toContinue) {
                    this.finalizeFormatAndCopy();
                    return;
                }
                this.backStep();
                return;
            }
            this.finalizeFormatAndCopy();

            return;
        }
    }

    backStep() {
        if (this.stepper.selectedIndex > 0) {
            this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
        }
    }

    promptFinished() {
        this.promptButtonLoading = true; // Start loader
        const promptValidationRequest: IPromptValidationRequest = {
            prompt: this.promptChild?.prompt,
            promptType: this.selectedPromptType,
        };

        console.log('promptValidationRequest', promptValidationRequest);

        this.projectAuthApiService
            .validatePrompt$(promptValidationRequest)
            .pipe(
                retry({
                    count: this.creativeApi.MAX_RETRY_ATTEMPTS,
                    delay: (error, retryCount) => this.creativeApi.shouldRetry(error, retryCount),
                }),
                takeUntil(this.onDestroy$),
                catchError((error) => {
                    console.error('Error:', error);
                    const errorKey = error.error?.error;
                    // Map error key to a user-friendly message
                    const errorMessage =
                        PromptValidationErrorMessagesEnum[errorKey] || 'An unknown error occurred. Please try again.';
                    this.snackbarService.openMessage(errorMessage, SnackbarActionEnum.Dismiss, 5000);
                    return throwError(() => error);
                }),
                finalize(() => {
                    this.promptButtonLoading = false;
                    console.log('Done');
                })
            )
            .subscribe({
                next: async (response) => {
                    this.nextStep();
                },
                error: (error) => {
                    console.error(`Could not validate prompt, error: ${error}`);
                },
            });
    }

    openLinkGuide() {
        this.dialog.open(GoogleLinkGuideComponent, {
            width: '500px',
            height: '500px',
            panelClass: 'google-link-guide',
        });
    }

    showErrorSnackbar(error: HttpErrorResponse) {
        // get the error code
        const errorCode = error?.error?.code;
        // get the error message
        const errorMessage = error?.error?.message;
        // Switch on the error code
        this.snackbarService.openMessage(
            error?.error?.message ?? 'Unknown error occurred. Please try again.',
            null,
            3000
        );
    }

    chooseSuggestion(suggestion: string) {
        this.promptChild.prompt = suggestion;
    }

    nextStep(isLastStep?: boolean) {
        if (isLastStep && !this.walkthroughPanelService.checkIfTaskCompleted(this.taskType)) {
            this.walkthroughPanelService.specialTaskCompleted$.next(this.taskType);
        }
        this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
    }

    createConfirmFreeProjectDialog(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const confirmationDialogData: IConfirmationModalData = {
                title: {
                    htmlContent: 'Hi 👋',
                    css: {
                        color: 'white',
                        style: { margin: '0', padding: '0' },
                    },
                    align: 'center',
                },
                body: [
                    {
                        align: 'center',
                        css: {
                            color: 'white',
                            style: { margin: '0' },
                        },
                        htmlContent: 'Ready to use up your FREE video project credit?',
                    },
                ],
                input: null,
                buttons: [
                    {
                        text: 'Back',
                        css: { color: 'default', style: {} },
                        callback: () => resolve(false), // Resolve the promise with `false`
                    },
                    {
                        text: 'Continue',
                        css: { color: 'primary', style: {} },
                        callback: () => resolve(true), // Resolve the promise with `true`
                    },
                ],
            };

            this.dialog.open(ConfirmationModalComponent, {
                width: '450px',
                data: confirmationDialogData,
            });
        });
    }
}
