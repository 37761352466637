import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IBasicLottieLayer } from 'src/app/models/lottie/lottie-defines';
import { TakeApiService } from '../api/auth/projects/take-api.service';
import { IProject } from 'src/app/models/project/project-model';
import { IScene } from 'src/app/models/project/scene-model';
import {
    ITake,
    ITakeInDTO,
    ITakeUpdate,
    TakeStatusEnum,
    TakeUpdateableProperties,
} from 'src/app/models/project/take/take-model';
import { TakeConverterService } from '../project/convertors/take/take-converter.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import { ProjectStoreService } from '../state-management/project/project-store.service';
import { TakeNotFoundError } from 'src/app/models/errors/project-errors/takes-errors';
import { IMediaModel } from '../../pages/private/dashboard/project/studio/studio-types';
import { MediaDevicesService } from '../recording/media-devices.service';
import { ProfileService } from '../show/profile.service';
import { LocalRecorderService } from '../show/local-recorder.service';
import { AnalyticsNotifierService } from '../utils/analytics-notifier.service';
import { StudioProjectManagerService } from './studio-project-manager.service';
import { PrompterSyncronizerService } from '../prompter-syncronizer.service';
import { VideoConvertorService } from '../project/convertors/take/layers/video-convertor.service';
import { RecordingProgressService } from './recording-progress.service';
import { SharedProjectDBService } from '../state-management/project/shared-project-indexdb.service';
import { ProjectSessionService } from '../state-management/project/project-session.service';
import { TakeSocketEventEmitterService } from '../state-management/project/socket/emitters/take-socket-event-emitter.service';
import * as hark from 'hark';

@Injectable()
export class RecordingManagerService {
    // Key -> Scene id, Value -> All the takes under this scene
    public takesMap = new Map<string, ITake[]>();
    mediaModel$ = new BehaviorSubject<IMediaModel>(null);
    mediaModelCheck: IMediaModel;
    public onDestroy$ = new Subject();
    recordingTimer: any = null;
    recordingTimeInSeconds: number = 0;
    public recordingTimeInSeconds$ = new BehaviorSubject<number>(0);

    // To know if the api call for appending the take to scene in server is currently working
    private isAppendingTakeToSceneSubject = new BehaviorSubject<boolean>(false);
    public isAppendingTakeToScene$ = this.isAppendingTakeToSceneSubject.asObservable();

    private isUpdatingTakeSubject = new BehaviorSubject<boolean>(false);
    public isUpdatingTake$ = this.isUpdatingTakeSubject.asObservable();

    private isCoundownInProgressSubject = new BehaviorSubject<boolean>(false);
    public isCountdownInProgress$ = this.isCoundownInProgressSubject.asObservable();

    private _isInitializingTakeToRecord = new BehaviorSubject<boolean>(false);
    public isInitializingTakeToRecord$ = this._isInitializingTakeToRecord.asObservable();

    private project: IProject;
    private currentScene: IScene;
    private currentTake: ITake;

    private currentStagePositionId: string;

    private _isFinishingTake$ = new BehaviorSubject<boolean>(false);
    public isFinishingTake$ = this._isFinishingTake$.asObservable();
    private activeStreamPromises: Promise<MediaStream | null>[] = [];
    private speechEvents: hark.Harker;

    private currentRecordingDetails: {
        projectId: string;
        sceneId: string;
        takeId: string;
        recordUniqueId: string;
        startTime: number;
    } | null = null;

    private newCurrentRecordingData: {
        scene: IScene;
        take: ITake;
        startTime: number;
    } | null = null;
    constructor(
        private projectStoreService: ProjectStoreService,
        private studioProjectManager: StudioProjectManagerService,
        private takeApiService: TakeApiService,
        private takeConvertor: TakeConverterService,
        private mediaDevicesService: MediaDevicesService,
        private localRecorderService: LocalRecorderService,
        private analyticsNotifierService: AnalyticsNotifierService,
        public profileService: ProfileService,
        private promptSync: PrompterSyncronizerService,
        private videoLayerConvertor: VideoConvertorService,
        private recordingProgressService: RecordingProgressService,
        private sharedProjectDBService: SharedProjectDBService,
        private projectSessionService: ProjectSessionService,
        private takeEventEmitter: TakeSocketEventEmitterService
    ) {
        this.subscribeToMediaDeviceChanges();
        this.subscribeToProjectManagerChanges();
        this.recordingProgressService.isRecordingInProgress$.subscribe((isRecordingInProgress) => {
            const timeout = isRecordingInProgress ? 500 : 0;
            setTimeout(() => {
                this.promptSync.setRequestToStartTeleprompt(isRecordingInProgress);
            }, timeout);
        });
        // this.voiceRegocnitionService.firstTimeSpoken$.subscribe({
        //   next: async (data) => {
        //     if (!this.recordingProgressService.isRecordingInProgress || !data) {
        //       return;
        //     }
        //     const trimStart = data.time - this.currentTake.startTime - 1000;
        //     if (trimStart < 0) return;
        //     try {
        //       await this.currentTake.updateVideoLayerTrimsAsync(
        //         this.currentTake.videoLayers[0].id,
        //         trimStart,
        //         null
        //       );
        //     } catch (error) {
        //       console.error(
        //         `An error occurred while updating video layer trims`,
        //         error
        //       );
        //     }
        //   },
        // });
    }

    public async stopMediaStreamAsync() {
        if (this.activeStreamPromises?.length > 0) {
            const streams = await Promise.allSettled(this.activeStreamPromises);
            const resolvedValues = streams
                .filter((result) => result.status === 'fulfilled') // Filter only fulfilled promises
                .map((result) => (result as PromiseFulfilledResult<MediaStream>).value); // Extract the value
            for (const stream of resolvedValues) {
                if (!stream) continue;

                stream.getTracks().forEach((track) => track.stop());
            }
            this.activeStreamPromises = [];
        }
        const mediaStream = this.mediaModel$.value?.mediaStream;
        if (!mediaStream) {
            return;
        }
        // Stop the media stream

        mediaStream.getTracks().forEach((track) => track.stop());
        this.mediaDevicesService.stopVoiceActivityDetection();
        this.mediaModel$.next(null);
    }

    subscribeToProjectManagerChanges() {
        this.studioProjectManager.project$.subscribe((project) => {
            if (!project) {
                return;
            }
            this.project = project;
        });

        this.studioProjectManager.currentScene$.subscribe((currentScene) => {
            if (!currentScene) {
                return;
            }
            this.currentScene = currentScene;
            this.promptSync.setCurrentPromptText(currentScene.copy.script);
            /// Currently by default we take the first stage position to be our stage position id,
            /// When we are able to choose, we will use the set function
            /// If we don't have stage positions because the array is empty (graphic scene),
            /// It will be null :)
            this.stagePositionId = currentScene.stagePositions[0]?.id;
        });

        this.studioProjectManager.currentTake$.subscribe((currentTake) => {
            if (!currentTake) {
                return;
            }
            this.currentTake = currentTake;
        });
    }

    public addTakeToSceneAsync(
        project: IProject,
        scene: IScene,
        dynamicLottieChanges: IDynamicLottieChange[],
        baseDesignPath: string
    ) {
        if (!project || !scene || !dynamicLottieChanges || !baseDesignPath) {
            throw new Error(`Could not add take to scene because one of the arguments is null`);
        }
        const sceneId = scene.id;
        const projectId = project.id;
        this.isAppendingTakeToSceneSubject.next(true);
        return new Promise<void>((resolve, reject) => {
            this.takeApiService.addTakeToScene$(projectId, sceneId, dynamicLottieChanges, true).subscribe({
                next: async (insertedTake) => {
                    if (!insertedTake) {
                        console.error(`Could not add take to scene!`);
                        ///TODO: Handle error
                        return;
                    }
                    const basicLottieLayers: IBasicLottieLayer[] = scene.composition.layouts.map((layout) => {
                        const basicLottieLayer: IBasicLottieLayer = {
                            lottieId: layout._id,
                            lottieJsonPath: layout.lottiePath,
                        };
                        return basicLottieLayer;
                    });
                    await this.setTakeInProjectStore(
                        insertedTake,
                        basicLottieLayers,
                        scene,
                        baseDesignPath,
                        project,
                        sceneId
                    );
                    return resolve();
                },
                error: (error) => {
                    console.error(`ERROR ${error}`);
                    return reject(`Could not add another take`);
                },
            });
        });
    }

    public async addVideoLayerToTakeAsync(project: IProject, scene: IScene, take: ITake) {
        const objectWithLowestPosition = scene.stagePositions.reduce((prev, curr) => {
            return curr.position < prev.position ? curr : prev;
        });
        /// Currently we send the first stage position in the array, when we will have 2 stage positions we will need to send which stage position we want :)
        const inVideoLayer = await take.addVideoLayerToTakeAsync(objectWithLowestPosition.id);
        const localVideoLayer = await this.videoLayerConvertor.inToLocalAsync(inVideoLayer, take, this.project.id);

        take.videoLayers = [localVideoLayer];
        this.addOrReplaceTake(project, scene, take);
    }

    private async setTakeInProjectStore(
        insertedTake: ITakeInDTO,
        basicLottieLayers: IBasicLottieLayer[],
        scene: IScene,
        baseDesignPath: string,
        project: IProject,
        sceneId: string
    ) {
        const localTake = await this.takeConvertor.inToLocalAsync(
            insertedTake,
            basicLottieLayers,
            scene.composition.layouts[0],
            baseDesignPath,
            false,
            project.id,
            scene.id,
            scene.name,
            project.indexDBData,
            scene.selectedTakeId === insertedTake.id
        );
        if (!this.takesMap.has(sceneId)) {
            this.setScenes([scene]);
        }

        scene.takes.push(localTake);
        // We know that in our db we updated to chosen take so it's fine to do that
        scene.selectedTakeId = localTake.id;

        const previousChosenTake = scene.chosenTake;
        if (previousChosenTake) {
            previousChosenTake.videoLayerDBController.removeTableFromProjectAsync();
        }
        scene.chosenTake = localTake;
        this.projectStoreService.replaceOrAddProjectTakes(this.project.id, scene.id, localTake);

        this.isAppendingTakeToSceneSubject.next(false);
    }

    /**
     *
     * @param scenes
     * Should be used once per chosen format at a time.
     * When used, clearing all current states about scenes in service
     */
    public setScenes(scenes: IScene[]) {
        if (!scenes) {
            throw new Error(`Could not set scenes because scenes are null.`);
        }
        scenes.forEach((scene) => {
            this.takesMap.set(scene.id, scene.takes);
        });
    }

    public getTake(scene: IScene, takeId: string) {
        const takes = scene?.takes;

        const updateTake = takes?.find((take) => take.id === takeId);
        if (!updateTake) {
            throw new TakeNotFoundError(`Could not update take because take with takeid: ${takeId} is not found`);
        }
        return updateTake;
    }

    /**
     *
     * @param projectId
     * @param scene
     * @param takeId
     * @param propertiesToUpdate
     * @returns
     */
    public updateTakePropertyAsync<K extends keyof TakeUpdateableProperties>(
        project: IProject,
        scene: IScene,
        takeId: string,
        baseDesignPath: string,
        propertiesToUpdate: ITakeUpdate<K>[],
        waitForLocalVideos: boolean,
        setLocalVideoLayer: boolean
    ) {
        if (!project || !scene || !takeId || !propertiesToUpdate) {
            throw new MissingArgumentsError(
                `Could not update demo take because one of the arguments is null or undefined.`
            );
        }
        if (propertiesToUpdate.length === 0) {
            return;
        }

        const takeToUpdate = this.getTake(scene, takeId);
        if (!takeToUpdate) {
            throw new Error(`Take with ID ${takeId} not found in scene ${scene}.`);
        }
        return new Promise<boolean>((resolve, reject) => {
            this.takeApiService.updateTake$(project.id, scene.id, takeId, propertiesToUpdate).subscribe({
                next: async (inTake) => {
                    if (!inTake) {
                        console.error(`Something strange happened while trying to update take.`);
                        return resolve(false);
                    }

                    await this.replaceOrAddTakeAsync(
                        project,
                        scene,
                        inTake,
                        baseDesignPath,
                        waitForLocalVideos,
                        setLocalVideoLayer
                    );

                    return resolve(true);
                },
                error(err: HttpErrorResponse) {
                    if (err.status === 500) {
                        //TODO: try again
                    }
                    console.error(`There was an error while updating take: ${err}`);
                    return reject(`There was an error while updating take`);
                },
            });
        });
        // updateTake[propertyName] = value;
    }

    async recordButtonClickedAsync() {
        // Check if recording has already started
        if (this.recordingProgressService.isRecordingInProgress) {
            // If recording has started, finish the current take
            try {
                await this.finishTakeAsync();
            } catch (e) {
                console.log(`An error occurred while trying to end a take when recording button was clicked`, e);
                return false;
            }
        }

        // Get the media stream from the media model
        const mediaStream = this.mediaModel$.value;

        // Check if the media stream exists
        if (!mediaStream) {
            return false;
        }

        // Set the 'wantToStartRecord' flag to true
        this.isCoundownInProgressSubject.next(true);

        return true;
    }

    /**
     * Updates the media stream asynchronously.
     *
     * This method stops the current media stream (if any) and obtains a new media stream from the MediaDevicesService.
     * The new media stream is then stored in the mediaModel$ subject.
     *
     * @returns {Promise<void>} A promise that resolves when the media stream is updated.
     */
    public async updateMediaStreamAsync(): Promise<void> {
        // Get the current media stream from the mediaModel$ subject
        const previousStream = this.mediaModel$.value;

        // Stop the current media stream, if it exists
        if (previousStream?.mediaStream) {
            previousStream.mediaStream.getTracks().forEach((track) => track?.stop());
        }

        // Create a promise to get the new media stream
        const streamPromise = new Promise<MediaStream | null>(async (resolve, reject) => {
            try {
                const newMediaStream = await this.mediaDevicesService.getMediaStreamAsync();
                resolve(newMediaStream);
            } catch (e) {
                reject(e); // Simply reject the error as it is
                resolve(null);
            }
        });
        // Add the promise to the isWaiting array
        this.activeStreamPromises.push(streamPromise);

        let resolvedMediaStream: MediaStream | null;
        try {
            resolvedMediaStream = await streamPromise; // This resolves to MediaStream | null
            this.speechEvents = hark(resolvedMediaStream, { threshold: -50, interval: 100 });

            this.speechEvents.on('speaking', () => {
                this.mediaDevicesService.setVoiceActive(true);
            });

            this.speechEvents.on('stopped_speaking', () => {
                this.mediaDevicesService.setVoiceActive(false);
            });
        } catch (e) {
            throw e; // Rethrow the error as it is
        }

        if (!resolvedMediaStream) return;

        // Create a new media model object with the user's ID and the new media stream
        const mediaModel: IMediaModel = {
            id: this.profileService.userPeer.id,
            mediaStream: resolvedMediaStream,
            stagePositionId: this.currentStagePositionId,
        };

        // Update the mediaModel$ subject with the new media model
        if (this.mediaModel$.value) {
            // If the mediaModel$ subject already has a value, update its properties
            this.mediaModel$.value.id = mediaModel.id;
            this.mediaModel$.value.mediaStream = mediaModel.mediaStream;
        } else {
            // If the mediaModel$ subject doesn't have a value, emit the new media model
            this.mediaModel$.next(mediaModel);
        }
    }

    toggleMicMute() {
        const currentMediaStream = this.mediaModel$.value;
        if (!currentMediaStream) {
            return;
        }
        const audioTrack = currentMediaStream.mediaStream?.getAudioTracks();
        if (audioTrack && audioTrack.length > 0) {
            audioTrack.forEach((track) => (track.enabled = !track.enabled));
        }
    }

    public async replaceOrAddTakeAsync(
        project: IProject,
        scene: IScene,
        inTake: ITakeInDTO,
        baseDesignPath: string,
        waitForLocalVideos: boolean,
        setLocalVideoLayer: boolean
    ) {
        const layout = scene.composition.layouts[0];
        const localTake = await this.takeConvertor.inToLocalAsync(
            inTake,
            null,
            layout,
            baseDesignPath,
            waitForLocalVideos,
            project.id,
            scene.id,
            scene.name,
            project.indexDBData,
            setLocalVideoLayer
        );
        this.addOrReplaceTake(project, scene, localTake);
    }

    private addOrReplaceTake(project: IProject, scene: IScene, localTake: ITake) {
        return this.projectStoreService.replaceOrAddProjectTakes(project.id, scene.id, localTake);
    }

    private async subscribeToMediaDeviceChanges() {
        this.mediaDevicesService.selectedCameraId$.subscribe((selectedCameraId: string) => {
            if (!selectedCameraId) {
                return;
            }
            this.updateMediaStreamAsync();
        });

        this.mediaDevicesService.selectedMicrophoneId$.subscribe((selectedMicrophoneId) => {
            if (!selectedMicrophoneId) {
                return;
            }
            this.updateMediaStreamAsync();
        });
    }

    /**
     * Will end the the countdown and actually start the recording
     * Caution: this should usually called from LiveSceneComponent after the countdown ended.
     */
    public async initiateRecordingAsync() {
        try {
            this._isInitializingTakeToRecord.next(true);
            this.sendRecordingEventToMixpanel();

            await this.setEverythingToRecordAsync();
            const startTime = Date.now();

            this.projectSessionService.lastRecordedScene = this.currentScene.id;
            const scene = this.project.scenes.find((scene) => scene.id === this.currentScene.id);
            const take = scene.takes.find((take) => take.id === this.currentTake.id);
            this.newCurrentRecordingData = {
                scene: scene,
                take: take,
                startTime: startTime,
            };
            this.currentRecordingDetails = {
                projectId: this.project.id,
                sceneId: this.currentScene.id,
                takeId: this.currentTake.id,
                recordUniqueId: this.currentTake.recordUniqueId,
                startTime: startTime,
            };

            this.isCoundownInProgressSubject.next(false);

            await this.settingTakeAsync(startTime);
            this.localRecorderService.startMediaRecorder(this.newCurrentRecordingData.take.recordUniqueId);

            this.recordingProgressService.setRecordingInProgress(true);

            this.recordingTimer = setInterval(() => {
                this.recordingTimeInSeconds++;
                // Convert seconds to milliseconds before sending to the timeformat pipe
                const recordingTimeInMilliseconds = this.recordingTimeInSeconds * 1000;

                this.recordingProgressService.RecordingTimeCounterValue = recordingTimeInMilliseconds;
            }, 1000);
        } finally {
            this._isInitializingTakeToRecord.next(false);
        }
    }

    private async settingTakeAsync(startTime: number) {
        const proeprtiesToUpdate: ITakeUpdate<'startTime' | 'status'>[] = [
            {
                key: 'startTime',
                value: startTime,
            },
            {
                key: 'status',
                value: TakeStatusEnum.RECORDING,
            },
        ];

        await this.updateTakePropertyAsync(
            this.project,
            this.newCurrentRecordingData.scene,
            this.newCurrentRecordingData.take.id,
            this.project.designGroup.chosenDesign.basePath,
            proeprtiesToUpdate,
            false,
            false
        );
    }

    public async finishTakeAsync() {
        try {
            this._isFinishingTake$.next(true);
            const isRecordInProgress = this.recordingProgressService.isRecordingInProgress;
            if (!this.currentRecordingDetails || !isRecordInProgress) {
                return true;
            }

            const endTime = Date.now();
            const duration = endTime - this.currentRecordingDetails.startTime;
            this.recordingProgressService.setRecordingInProgress(false);

            // Reset the recording timer
            if (this.recordingTimer) {
                clearInterval(this.recordingTimer);
                this.recordingTimeInSeconds = 0;
                this.recordingProgressService.RecordingTimeCounterValue = this.recordingTimeInSeconds;
            }

            await this.stopRecordForAllStreamsAsync(this.newCurrentRecordingData.take.recordUniqueId);

            const proeprtiesToUpdate: ITakeUpdate<'duration' | 'endTime' | 'status'>[] = [
                {
                    key: 'endTime',
                    value: endTime,
                },
                {
                    key: 'duration',
                    value: duration,
                },
                {
                    key: 'status',
                    value: TakeStatusEnum.RECORDED,
                },
            ];

            await this.updateTakePropertyAsync(
                this.project,
                this.newCurrentRecordingData.scene,
                this.newCurrentRecordingData.take.id,
                this.project.designGroup.chosenDesign.basePath,
                proeprtiesToUpdate,
                true,
                true
            );
            this.takeEventEmitter.notifyTakeEnded({
                projectId: this.project.id,
                sceneId: this.newCurrentRecordingData.scene.id,
                takeId: this.newCurrentRecordingData.take.id,
            });
            // Inside updating take properties, it will build the lottie video configs,
            // So we will see the lottie video composed and not the live scene if staying
            // On the existed scene
            this.localRecorderService.removeBlobObjectFromLocal(this.newCurrentRecordingData.take.recordUniqueId);
        } catch (error) {
            return false;
        } finally {
            this._isFinishingTake$.next(false);
            this.newCurrentRecordingData = null;
        }
    }

    private async stopRecordForAllStreamsAsync(recordUniqueId: string) {
        // Since stopRecording does not return a Promise, we don't await it here
        // but we must ensure stopRecording has completed before calling getRecording
        await this.localRecorderService.stopRecordingAsync(recordUniqueId);
        try {
            const info = this.sharedProjectDBService.getIndexDBConfigs(this.project.id);
            await this.sharedProjectDBService.setCurrentProjectIndexDataAsync(
                this.project.indexDBData.dexiePromise,
                info.dbName,
                info.storeName
            );
        } catch (projectDBError) {
            console.error(`There was an error while trying to use project db`, projectDBError);
        }
    }

    private async setEverythingToRecordAsync() {
        const mediaModel = this.mediaModel$.value;
        const recordUniqueId = this.currentTake.recordUniqueId;

        // Adding the video layer to the take at the server + to the local take
        await this.addVideoLayerToTakeAsync(this.project, this.currentScene, this.currentTake);

        const deepCopyOfTake = this.currentTake.clone(this.project.id);
        this.localRecorderService.setMediaRecorder(mediaModel.mediaStream, recordUniqueId, deepCopyOfTake);
    }

    public updateTakeSetupAsync(scene: IScene, take: ITake) {
        return new Promise<boolean>((resolve, reject) => {
            const proeprtiesToUpdate: ITakeUpdate<'copy'>[] = [
                {
                    key: 'copy',
                    value: take.copy,
                },
            ];
            this.takeApiService.updateTake$(this.project.id, scene.id, take.id, proeprtiesToUpdate).subscribe({
                next: async (inTake) => {
                    if (!inTake) {
                        console.error(`Something strange happened while trying to update take.`);
                        return resolve(false);
                    }
                    await this.updateTakePropertyAsync(
                        this.project,
                        this.currentScene,
                        take.id,
                        this.project.designGroup.chosenDesign.basePath,
                        proeprtiesToUpdate,
                        false,
                        false
                    );
                },
            });
        });
    }

    private sendRecordingEventToMixpanel() {
        const didRecordBeforeKey = this.profileService.didRecordLocalStorageKey;
        let didRecord: boolean = false;
        // the key might not be existed so it will fail to json parse.
        try {
            didRecord = JSON.parse(localStorage.getItem(didRecordBeforeKey));

            if (!didRecord) {
                // const sceneType: 'voiceover' | 'video' =
                //     this.currentCustomLayout.name === 'none' ? 'voiceover' : 'video';
                const data = {
                    scene_type: 'video',
                    recordStartedAt: new Date().toISOString(),
                    projectCreatedAt: this.project.createdAt,
                };
                this.analyticsNotifierService.notifyEvent(`Record Take`, data);
                localStorage.setItem(didRecordBeforeKey, JSON.stringify(true));
            }
        } catch (error) {}
    }

    public set stagePositionId(stagePositionId: string) {
        this.currentStagePositionId = stagePositionId;
    }
}
