<div *ngIf="(isLoading | async) && showLoadingIndication" class="loading-progress-spinner">
    <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<div
    *ngIf="this.composedEditTakes$ | async as composedEditTakes"
    [class.hideme]="isHidden || ((isLoading | async) && showLoadingIndication)"
    class="all-wrapper">
    <ng-container *ngFor="let composedEditTake of composedEditTakes; index as editTakeIndex">
        <ng-container *ngFor="let layerAndOptions of composedEditTake.lottiesLayersAndOptions; index as lottieIndex">
            <dynamic-lottie
                (animationCreated)="
                    animationCreated($event, layerAndOptions, composedEditTake.id, layerAndOptions.lottieLayer.lottieId)
                "
                (destroy)="animationDestroyed(composedEditTake.id + layerAndOptions.lottieLayer.lottieId)"
                [class.hideme]="layerAndOptions.isHidden$ | async"
                [config]="layerAndOptions.lottieData"
                [lottieOptions]="layerAndOptions.options"
                class="lottie-display"
                >>
            </dynamic-lottie>
            <!-- <ng-lottie
                    class="lottie-display"
                    (destroy)="
                      animationDestroyed(
                        composedScene.id + layerAndOptions.lottieLayer.lottieId
                      )
                    "
                    [class.hideme]="layerAndOptions.isHidden$ | async"
                    [options]="layerAndOptions.options"
                    (animationCreated)="
                      animationCreated(
                        $event,
                        layerAndOptions,
                        composedScene.id,
                        layerAndOptions.lottieLayer.lottieId
                      )
                    ">
                  </ng-lottie> -->
        </ng-container>
        <ng-container *ngIf="composedEditTake.videoLayers as videoLayers">
            <app-scrub-bar
                (dragEndedEmitter)="dragEndedFromScrubBar()"
                (dragStartedEmitter)="dragStartedFromScrubBar()"
                (newSeekInMillisEmitter)="newTimeFromScrubBar($event)"
                *ngIf="videoLayers.length === 0"
                [vgApiId]="composedEditTake.id"
                class="scrub-bar"></app-scrub-bar>
            <div *ngIf="videoLayers.length > 0" class="video-layer" style="width: 100%; height: 100%">
                <vg-player
                    #vgPlayer
                    (onPlayerReady)="onPlayerReady($event, composedEditTake.id, videoLayers[0])"
                    [id]="composedEditTake.id">
                    <vg-controls
                        (drag)="$event.stopPropagation()"
                        (dragstart)="$event.stopPropagation()"
                        (mousedown)="$event.stopPropagation()"
                        *ngIf="this.displayVgControls && areVideosReady$ | async"
                        [vgAutohideTime]="2.5"
                        [vgAutohide]="true">
                        <!-- (vgPlay)="onVgPlayPausea($event)" -->
                        <!-- <vg-scrub-bar #customScrubBar>
                          <vg-scrub-bar-current-time
                            [vgSlider]="true"
                          ></vg-scrub-bar-current-time>
                        </vg-scrub-bar> -->
                        <app-scrub-bar
                            (dragEndedEmitter)="dragEndedFromScrubBar()"
                            (dragStartedEmitter)="dragStartedFromScrubBar()"
                            (newSeekInMillisEmitter)="newTimeFromScrubBar($event)"
                            [duration]="
                                totalTrackDuration ? totalTrackDuration : composedEditTake.durationInMilliseconds
                            "
                            [currentTime]="currentTime"
                            [navigateFromEditRoom]="this.navigateFromEditRoom"
                            [vgApiId]="composedEditTake.id"
                            class="scrub-bar"></app-scrub-bar>
                        <div class="bottom-row row-centered">
                            <button
                                (click)="pauseOrPlayPressed(composedEditTake.id)"
                                [matTooltip]="playOrPause.isPlaying ? 'Pause' : 'Play'"
                                class="play-button"
                                mat-icon-button
                                tabindex="0">
                                <mat-icon>
                                    {{ playOrPause.isPlaying ? 'pause' : 'play_arrow' }}
                                </mat-icon>
                            </button>
                            <div class="row-centered" *ngIf="!navigateFromEditRoom; else editRoomTemplate">
                                <vg-time-display vgFormat="mm:ss /" vgProperty="current"></vg-time-display>

                                <div class="time-display">
                                    {{ composedEditTake.durationInMilliseconds | timeFormat }}
                                </div>
                            </div>

                            <ng-template #editRoomTemplate>
                                <div class="row-centered">
                                    <div class="time-display">
                                        {{ currentTime | timeFormat }} / {{ totalTrackDuration | timeFormat }}
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </vg-controls>

                    <ng-container *ngFor="let videoLayer of videoLayers; let i = index">
                        <!-- custom-video.component.html -->
                        <video
                            #media
                            *ngIf="!isIOS && !videoLayer.localUrlConfigs && videoLayer.dashPath; else noDash"
                            [class.flip-horizontally]="flipHorizontally"
                            [class.hideme]="toHideVideoLayer"
                            [id]="composedEditTake.id + editTakeIndex"
                            [style.height]="videoLayer.videoPosition.height + '%'"
                            [style.left]="videoLayer.videoPosition.x + '%'"
                            [style.top]="videoLayer.videoPosition.y + '%'"
                            [style.width]="videoLayer.videoPosition.width + '%'"
                            [vgDash]="baseCdnUrl + videoLayer.dashPath"
                            [vgMaster]="i === 0"
                            [vgMedia]="$any(media)"
                            class="no-controls"
                            disablePictureInPicture
                            playsinline="true"
                            preload="auto"></video>
                        <!-- [src]="baseCdnUrl + videoLayer.uploadPath" -->

                        <ng-template #noDash>
                            <video
                                #media
                                [appVideoSource]="{
                                    localUrl: videoLayer.localUrlConfigs,
                                    uploadUrl: baseCdnUrl + videoLayer.uploadPath,
                                }"
                                [class.flip-horizontally]="flipHorizontally"
                                [class.hideme]="toHideVideoLayer"
                                [id]="composedEditTake.id + editTakeIndex"
                                [style.height]="videoLayer.videoPosition.height + '%'"
                                [style.left]="videoLayer.videoPosition.x + '%'"
                                [style.top]="videoLayer.videoPosition.y + '%'"
                                [style.width]="videoLayer.videoPosition.width + '%'"
                                [vgMaster]="i === 0"
                                [vgMedia]="$any(media)"
                                class="no-controls"
                                disablePictureInPicture
                                playsinline="true"
                                preload="auto"></video>
                        </ng-template>
                    </ng-container>

                    <!-- crossorigin -->
                    <!-- <audio
                          #audioMedia
                          [vgMedia]="audioMedia"
                          id="audioMedia"
                          preload="auto"
                          [volume]="0.3"
                        >
                          <source
                            *ngFor="let audio of audioScenesOnTrack"
                            [src]="this.baseCdnUrl + audio.audioPath"
                            type="audio/mpeg"
                          />
                        </audio> -->
                </vg-player>
            </div>
        </ng-container>
    </ng-container>
</div>

<canvas
    #subtitlesCanvas
    class="subtitles-canvas"
    [class.aspect-916]="composeConfigs?.aspectRatio === aspectRatioEnum._9x16"
    [class.aspect-11]="composeConfigs?.aspectRatio === aspectRatioEnum._1x1"></canvas>

<div (click)="requestPlayOrPause(!this.playOrPause.isPlaying)" *ngIf="showPlayPauseOverlay" class="play-pause-overlay">
    <div class="icon-wrap">
        <mat-icon *ngIf="!this.playOrPause.isPlaying" class="play-icon" [@flipInXOnEnter] [@bounceOutOnLeave]
            >play_arrow
        </mat-icon>
    </div>
</div>
