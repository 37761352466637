import { Injectable } from '@angular/core';
import { handleApiCallAsync } from '../../../../utils/general-functions';
import { IProject, IProjectInDTO } from '../../../../models/project/project-model';
import { ProjectAuthApiService } from '../../../api/auth/projects/project-auth-api.service';
import { UserProjectEventListenerService } from '../../user/socket/listeners/user-project-event-listener.service';
import { Subject } from 'rxjs';

@Injectable()
export class ProjectMetadataManagerService {
    private _updatedProject$ = new Subject<IProjectInDTO>();
    public updatedProject$ = this._updatedProject$.asObservable();
    constructor(
        private projectEventListener: UserProjectEventListenerService,
        private projectAuthApiService: ProjectAuthApiService
    ) {
        this.projectEventListener.projectUpdatedToFetch$.subscribe(async (data) => {
            /// Only making this api call if I have any subscriptions to get the updated project
            /// So I won't make useless api calls for free
            if (!this._updatedProject$.observed) {
                console.log(`GOT A PROJECT TO FETCH BUT NO OBSERVED!`, data);
                return;
            }
            if (!data?.projectId) return;
            console.log(`GOT A PROJECT TO FETCH!`, data);
            const project = await this.getSpecificProjectMetadataAsync(data.projectId);
            if (!project) return;

            this._updatedProject$.next(project);
        });
    }

    updateProjectsList<T extends IProjectInDTO>(currentProjects: T[], newProject: T, maxItems: number | null): void {
        const projectIndex = currentProjects.findIndex((project) => project.id === newProject.id);

        // If the project exists, update it directly in place
        if (projectIndex !== -1) {
            Object.assign(currentProjects[projectIndex], newProject); // Mutate in place
            return;
        }

        // If max items is not reached, add the new project
        if (!maxItems || currentProjects.length < maxItems) {
            currentProjects.push(newProject);
            return;
        }

        // Find the oldest project and replace it
        if (currentProjects.length > 0) {
            const oldestProjectIndex = currentProjects.reduce((oldestIndex, project, index, array) => {
                return new Date(project.updatedAt).getTime() < new Date(array[oldestIndex].updatedAt).getTime()
                    ? index
                    : oldestIndex;
            }, 0); // Start with index 0 (ensuring a valid index)

            if (oldestProjectIndex !== -1) {
                Object.assign(currentProjects[oldestProjectIndex], newProject); // Mutate in place
            }
        }
    }

    async getSpecificProjectMetadataAsync(projectId: string) {
        const { data: projects, error } = await handleApiCallAsync(
            this.projectAuthApiService.getProjectList$({ projectId: projectId, limit: 0, skip: 0 })
        );
        if (error) {
            return null;
        }
        let newProject: IProjectInDTO;
        if (Array.isArray(projects)) {
            newProject = projects.find((project) => project.id === projectId);
        } else {
            newProject = projects;
        }

        return newProject;
    }
}
