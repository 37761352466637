// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './IEnvironment';

export const environment: IEnvironment = {
    production: false,
    debugLog: true,
    enforceInvitations: true,
    prettyUrl: 'https://shuffllmedia.com',
    socketUrl: 'wss://shuffllmedia.com:3201',
    api: 'https://shuffllmedia.com:8081/api/v1',
    unleashConfig: {
        url: 'https://us.app.unleash-hosted.com/usbb1021/api/frontend',
        clientKey: 'shuffll-ec623d619bc6:development.238c8cff017382ca1df6069d8028d3f6d95c3f3a873064490de9eb86',
        appName: 'Shuffll',
        refreshInterval: 3 * 1000,
        cacheInterval: 3 * 1000,
    },
    stripe: {
        publishableKey:
            'pk_test_51JjfJ5JwhYEEanQxFE5KyaBthspQmyrIttPuMer5xq8PodQcNmaJ0234r9ru43APRL2sMtxS8mF3VeoIzg4Y0TwL00iXy7D3ht',
    },
    hubspotProxyApi: 'https://us-central1-shuffll-backend.cloudfunctions.net/Hubspot-Proxy',
    googleFunctions: 'https://europe-west3-shuffll-beta-signups.cloudfunctions.net',
    mediasoup: 'mediasoup.shuffllmedia.com',
    // mediasoup: 'mediasoup.localhost:4003',
    intercomKey: 'hn18clua',
    mixpanelToken: 'ec0fa41b34138f88a537e2a11d0fe1af',
    trackUserEvents: false,
    baseCdnUrl: 'https://content.shuffll.com',
    googleClientId: '521811392962-bqi728jc3akambuc6qk8nqrn20lkom72',
    productFruitsWorkspaceId: 'SIMRwe6OQXieaB7r',
    version: 'We ♥ NM - 25.1.37',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
