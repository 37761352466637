<mat-stepper
    #stepper
    (selectionChange)="stepChanged($event)"
    [linear]="isLinear"
    id="produce-wizard-stepper"
    [ngClass.lt-sm]="'mobile'">
    <mat-step [stepControl]="subjectFormGroup" label="Idea">
        <div>
            <div #loadingIndicator *ngIf="isLoadingPromptSuggestions" class="spinner-holder">
                <loading-indicator></loading-indicator>
            </div>
        </div>
        <div>
            <div *ngIf="!isLoadingPromptSuggestions" id="idea-step">
                <span class="title-wrapper">
                    <h2>How would you like to create your script?</h2>
                    <h6 *ngIf="toDisplayVideoTypeStep && toDisplayVideoTypeStepFlag">Step 1/2</h6>
                </span>

                <mat-chip-listbox [@fadeInOnEnter] aria-label="Prompt type">
                    <mat-chip-option
                        (selectionChange)="changePromptType(promptType)"
                        *ngFor="let promptType of promptTypesList"
                        [selected]="promptType === selectedPromptType"
                        [value]="promptType"
                        class="prompt-time-chip">
                        <div class="row-centered gapped">
                            <ng-container *ngIf="promptType === PromptTypeEnum.SIMPLE_PROMPT">
                                <mat-icon *ngIf="promptType !== selectedPromptType" class="small-icon chip-icon">
                                    text_fields
                                </mat-icon>

                                {{ 'Describe my script' }}</ng-container
                            >
                            <ng-container *ngIf="promptType === PromptTypeEnum.LONG_SCRIPT">
                                <mat-icon *ngIf="promptType !== selectedPromptType" class="small-ico chip-iconn">
                                    subject
                                </mat-icon>

                                {{ 'I have my own script' }}</ng-container
                            >
                            <ng-container *ngIf="promptType === PromptTypeEnum.LINK">
                                <mat-icon *ngIf="promptType !== selectedPromptType" class="small-icon chip-icon"
                                    >link
                                </mat-icon>

                                {{ 'I have a link' }}</ng-container
                            >
                        </div>
                    </mat-chip-option>
                </mat-chip-listbox>

                <div class="prompt-input-wrapper" [@fadeInOnEnter] *ngIf="selectedPromptType">
                    <prompt
                        #prompt
                        [promptType]="selectedPromptType"
                        [toneOfVoiceList]="toneOfVoiceList"
                        [storytellingTechniqueList]="storyTellingTeqniquesList"
                        [loading]="promptButtonLoading"
                        [injectPrompt]="injectPrompt"
                        (finish)="promptFinished()"></prompt>
                </div>

                <div style="min-height: 238px; margin-top: 18px" *ngIf="toDisplaySuggestions">
                    <div *ngIf="suggestionsReady" [@fadeInOnEnter]>
                        <!--                        <mat-chip-listbox [@fadeInOnEnter] aria-label="Tags Selection">-->
                        <!--                            <mat-chip-option-->
                        <!--                                (selectionChange)="changeVideoTag(tag)"-->
                        <!--                                *ngFor="let tag of videoTags"-->
                        <!--                                [selected]="tag === selectedVideoTag"-->
                        <!--                                [value]="tag"-->
                        <!--                                >{{ tag.title }}-->
                        <!--                            </mat-chip-option>-->
                        <!--                        </mat-chip-listbox>-->
                        <div style="height: 10px">
                            <mat-progress-bar *ngIf="!suggestions" mode="indeterminate"></mat-progress-bar>
                        </div>
                        <h6 class="suggestions-title">
                            {{ suggestions ? 'Video ideas tailored for you' : 'Generating Suggestions' }}
                        </h6>
                        <mat-selection-list #goalsList [multiple]="false" class="suggestion-list">
                            <mat-list-option
                                (click)="chooseSuggestion(suggestion)"
                                *ngFor="let suggestion of suggestions"
                                >{{ suggestion }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                    <div *ngIf="showLinkGuide" [@fadeInOnEnter]>
                        <div class="italic">
                            Using Google Docs or Slides? Make sure the link is public
                            <span class="clickable" (click)="openLinkGuide()">Learn more</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-step>
    <mat-step label="Type" *ngIf="toDisplayVideoTypeStep && toDisplayVideoTypeStepFlag">
        <recording-type-step (finished)="recordingTypeSelected($event)"></recording-type-step>
    </mat-step>

    <!--  TODO: Formats were here-->
    <mat-step #finalLoading label="load">
        <div id="loader-step">
            <loading-with-swag #loadingWithSwagFinal [steps]="finalLoadingSteps"></loading-with-swag>
        </div>
    </mat-step>
</mat-stepper>

<div *ngIf="stepper" class="buttons-area">
    <button (click)="backStep()" *ngIf="stepper.selectedIndex > 0" mat-button mat-flat-button>
        <mat-icon style="font-size: 10px; width: 10px; height: 9px">arrow_back_ios</mat-icon>
        Back
    </button>
    <div class="dummy"></div>
</div>
