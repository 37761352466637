<mat-progress-bar
    mode="indeterminate"
    [class.hideme]="!this.loadingProjects && !this.loadingTemplates && !this.loadingVideoIdeas">
</mat-progress-bar>
<ng-template #homepageLoader>
    <div class="spinner-holder">
        <loading-indicator></loading-indicator>
    </div>
</ng-template>

<ng-container *ngIf="!this.loadingProjects || !this.loadingTemplates || !this.loadingVideoIdeas; else homepageLoader">
    <div class="dashboard-page padded" [ngClass.lt-sm]="'mobile'">
        <app-sidenav-toggle></app-sidenav-toggle>

        <div class="popout-container">
            <div class="row-centered gapped">How would you like to create your video?</div>

            <div class="buttons row-centered big-gapped">
                <button
                    mat-raised-button
                    color="secondary"
                    (click)="createNewProduction({ recordingType: RecordingType.CAMERA }, 'videoTypes')">
                    Record a Video
                </button>
                <button
                    mat-raised-button
                    (click)="createNewProduction({ recordingType: RecordingType.MIC }, 'videoTypes')">
                    Record Voiceover
                </button>
                <button
                    mat-raised-button
                    (click)="createNewProduction({ recordingType: RecordingType.AI_VOICE }, 'videoTypes')">
                    AI Voiceover
                </button>
            </div>
        </div>
        <div class="section project-ideas-section">
            <ng-template #videoIdeasLoader>
                <div class="section-spinner-holder">
                    <loading-indicator></loading-indicator>
                </div>
            </ng-template>
            <ng-container *ngIf="!this.loadingVideoIdeas; else videoIdeasLoader">
                <div class="header row-spacebetween no-borders">
                    <h2
                        class="header__title"
                        *ngIf="videoIdeaStatus !== UserVideoIdeaStatusEnum.NOT_STARTED"
                        fxShow
                        fxHide.lt-sm>
                        {{
                            this.user.branding?.company?.name
                                ? 'Personalized Video Ideas For ' + this.user.branding.company.name
                                : 'My Personalized Video Ideas'
                        }}
                    </h2>
                </div>

                <div class="ideas-status" [ngSwitch]="videoIdeaStatus">
                    <div class="ideas-list" *ngSwitchCase="UserVideoIdeaStatusEnum.COMPLETED">
                        <div
                            class="idea-card-wrap"
                            *ngFor="let videoIdea of displayedVidedIdeas"
                            (click)="
                                createNewProduction(
                                    {
                                        prompt: videoIdea.prompt,
                                        designGroupId: videoIdea.designGroup.id,
                                    },
                                    'videoIdea'
                                );
                                notifySimpleProjectCreation('Homepage Video Idea', videoIdea.prompt)
                            ">
                            <video-idea-card
                                [dynamicValues]="personalizedDynamics"
                                [videoIdea]="videoIdea"></video-idea-card>
                        </div>
                    </div>
                    <div *ngSwitchCase="UserVideoIdeaStatusEnum.IN_PROGRESS">
                        <div class="header-container">
                            <h5 class="centered-header">Generating personal video ideas</h5>
                        </div>
                        <div class="ideas-list">
                            <div class="idea-card-wrap" *ngFor="let videoIdea of dummyCards">
                                <video-idea-card [dummy]="true"></video-idea-card>
                            </div>
                        </div>
                    </div>
                    <div *ngSwitchCase="UserVideoIdeaStatusEnum.NOT_STARTED || UserVideoIdeaStatusEnum.FAILED">
                        <onboarding-branding-automatic-import class="import-branding" [isFromHomePage]="true">
                        </onboarding-branding-automatic-import>
                    </div>
                    <div *ngSwitchDefault>
                        <onboarding-branding-automatic-import class="import-branding" [isFromHomePage]="true">
                        </onboarding-branding-automatic-import>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="section last-projects-section">
            <ng-template #projectLoader>
                <div class="section-spinner-holder">
                    <loading-indicator></loading-indicator>
                </div>
            </ng-template>
            <ng-container *ngIf="!this.loadingProjects; else projectLoader">
                <div class="header no-borders">
                    <h2 class="header__title">My Projects</h2>
                    <div>
                        <button *ngIf="lastProjects?.length > 0" mat-button (click)="goToLibrary()">Show All</button>
                    </div>
                </div>
                <div class="project-list minimal">
                    <div class="project-card-wrap" *ngFor="let project of lastProjects">
                        <project-card
                            [project]="project"
                            [minimal]="true"
                            (click)="goToProject(project)"></project-card>
                    </div>
                    <div
                        class="project-card-wrap empty clickable"
                        (click)="
                            createNewProduction({}, 'createProjectButton');
                            notifySimpleProjectCreation('Homepage Create New Project', null)
                        ">
                        <button mat-fab class="icon-new-project">
                            <mat-icon>add</mat-icon>
                        </button>
                        <div>{{ lastProjects?.length > 0 ? 'Create New Project' : 'Create My First Project' }}</div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="section templates-section">
            <ng-template #projectTemplatesLoader>
                <div class="section-spinner-holder">
                    <loading-indicator></loading-indicator>
                </div>
            </ng-template>
            <ng-container *ngIf="!this.loadingTemplates && templates?.length > 0; else projectTemplatesLoader">
                <div class="header row-spacebetween no-borders">
                    <h2 class="header__title">Start with a Template</h2>
                </div>
                <ngx-slick-carousel
                    #slickCarousel="slick-carousel"
                    [config]="templateSliderConfig"
                    class="template-list">
                    <div ngxSlickItem class="template-card-wrap" *ngFor="let template of templates">
                        <template-card [template]="template" (click)="goToTemplate(template)"></template-card>
                    </div>
                </ngx-slick-carousel>
            </ng-container>
        </div>
    </div>
</ng-container>
