import { Injectable } from '@angular/core';
import { ITake, ITakeConfigs, ITakeInDTO, ITakeOutDTO, Take } from 'src/app/models/project/take/take-model';
import { VideoConvertorService } from './layers/video-convertor.service';
import { LottieConvertorService } from './layers/lottie-convertor.service';
import { v4 as uuidv4 } from 'uuid';
import { IBasicLottieLayer, IDynamicLottieData } from 'src/app/models/lottie/lottie-defines';
import { IComposeAndPlayOrNot, IComposedEditTakeConfigs, IVideoLottieCompose } from 'src/app/models/job/editor-defines';
import { IVideoLayer } from 'src/app/models/project/take/layers/video-model';
import { IConvertorAsync } from 'src/app/models/project/project-model';
import { VideoLayerIndexdbController } from 'src/app/models/project/controllers/video-layer-indexd.controller';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FunctionsHelperService } from 'src/app/services/functions-helper.service';
import {
    IProjectIndexData,
    SharedProjectDBService,
} from 'src/app/services/state-management/project/shared-project-indexdb.service';
import { VideoLayerApiService } from 'src/app/services/api/auth/projects/video-layer-api.service';
import { ProfileService } from 'src/app/services/show/profile.service';
import { ILayout } from 'src/app/models/defines';
import { DbService } from '../../../dexie/db.service';

@Injectable({
    providedIn: 'root',
})
export class TakeConverterService implements IConvertorAsync<ITakeInDTO, ITake, ITakeOutDTO> {
    constructor(
        private videoConvertor: VideoConvertorService,
        private lottieConvertor: LottieConvertorService,
        private functionsHelper: FunctionsHelperService,
        private http: HttpClient,
        private config: ConfigurationService,
        private readonly videoLayerApi: VideoLayerApiService,
        private readonly profileService: ProfileService,
        private dexieService: DbService,
        private sharedProjectDBService: SharedProjectDBService
    ) {}

    async inToLocalAsync(
        inTake: ITakeInDTO,
        basicLottieLayers: IBasicLottieLayer[] | null,
        layout: ILayout,
        baseDesignPath: string,
        waitForLocalVideos: boolean,
        projectId: string,
        sceneId: string,
        sceneName: string,
        projectDBData: IProjectIndexData | null,
        downloadLocalVideo: boolean
    ) {
        if (!inTake) {
            console.error(`Cannot convert take  to out dto take because it's null.`);
            return;
        }
        const videoLayers = await Promise.all(
            inTake.videoLayers?.map((videoLayer) =>
                this.videoConvertor.inToLocalAsync(videoLayer, inTake, projectId)
            ) ?? []
        );

        const lottieLayers = inTake.lottieLayers.map((lottieLayer) => this.lottieConvertor.inToLocal(lottieLayer));
        const recordUniqueId = this.getGeneratedRecordId(inTake.id, sceneId);

        const { startTime, endTime } = this.getStartAndEndTime(inTake);

        const dynamicData: IDynamicLottieData = {
            layout: layout,
            dynamicLottieChanges: inTake.copy.dynamicLottieChanges,
            basePath: baseDesignPath,
        };
        if (!basicLottieLayers) {
            basicLottieLayers = this.getBasiclottieLayers(layout);
        }
        // if (inTake.status === TakeStatusEnum.RECORDED) {
        const lottieVideoConfigs = this.getLottieVideoComposedConfigs(
            basicLottieLayers,
            videoLayers,
            dynamicData,
            startTime,
            endTime
        );
        const numberOfTake = isNaN(inTake.number) ? '' : `${inTake.number + 1}`;
        const title = `Take ${numberOfTake}`;
        projectDBData = projectDBData ?? this.sharedProjectDBService.getCurrentProjectIndexData;

        const videoLayerController = new VideoLayerIndexdbController(
            projectDBData,
            videoLayers,
            this.functionsHelper,
            this.http,
            this.config,
            this.dexieService,
            this.sharedProjectDBService
        );
        /// We only want to download specific takes to IndexDB (like selected take) and not all the takes
        if (downloadLocalVideo) {
            const setLocalFilesPromise = videoLayerController.setVideoLayersLocalFilePathAsync();
            /// If not waiting for this to happen, it will be in the background.
            if (waitForLocalVideos) {
                try {
                    await setLocalFilesPromise;
                } catch (error) {
                    console.warn(error);
                }
            }
        } else {
            // await videoLayerController.removeTableFromProjectAsync();
        }
        const take: ITakeConfigs = {
            id: inTake.id,
            copy: inTake.copy,
            duration: inTake.duration,
            startTime: inTake.startTime,
            endTime: inTake.endTime,
            status: inTake.status,
            lottieLayers: lottieLayers,
            number: inTake.number,
            videoLayers: videoLayers,
            recordUniqueId: recordUniqueId,
            lottieComposedConfigs: lottieVideoConfigs,
            title: title,
            isFavorite: inTake.isFavorite,
        };
        const newTake = new Take(
            take,
            projectId,
            sceneId,
            sceneName,
            videoLayerController,
            this.videoLayerApi,
            this.profileService
        );

        return newTake;
    }

    public getStartAndEndTime(inTake: ITakeInDTO) {
        const startTime = 0;
        let endTime: number;
        if (inTake.endTime) {
            endTime = inTake.endTime - inTake.startTime;
        }
        return { startTime, endTime };
    }

    localToOut(take: ITake) {
        if (!take) {
            console.error(`Cannot convert take  to out dto take because it's null.`);
            return;
        }

        const outDTOVideoLayers = take.videoLayers?.map((videoLayer) => this.videoConvertor.localToOut(videoLayer));

        const outDTOLottieLayers = take.lottieLayers.map((lottieLayer) => this.lottieConvertor.localToOut(lottieLayer));

        const outDTOTake: ITakeOutDTO = {
            id: take.id,
            startTime: take.startTime,
            endTime: take.endTime,
            duration: take.duration,
            videoLayers: outDTOVideoLayers,
            lottieLayers: outDTOLottieLayers,
            number: take.number,
            copy: take.copy,
            status: take.status,
            isFavorite: take.isFavorite,
        };
        return outDTOTake;
    }

    getGeneratedRecordId(takeId: string, sceneId: string) {
        if (!takeId || !sceneId) {
            console.error(`Could not generate record id because one of the arguments is null.`);
            return;
        }

        return `${takeId}-${sceneId}`;
    }

    getLottieVideoComposedConfigs(
        lottieLayers: IBasicLottieLayer[],
        videoLayers: IVideoLayer[],
        lottieDynamicData: IDynamicLottieData,
        startTime: number,
        endTime: number
    ): IComposeAndPlayOrNot | null {
        if (!lottieLayers) {
            console.error(`Could not get lottie video composed configs because in take is null`);
            return null;
        }
        const updatedBasicLottieLayers = lottieLayers.map((lottieLayer) => {
            const basicLottieLayer: IBasicLottieLayer = {
                lottieId: lottieLayer.lottieId,
                lottieJsonPath: lottieLayer.lottieJsonPath,
                startTime: lottieLayer.startTime ?? 0,
                endTime: lottieLayer.endTime ?? 0,
            };
            return basicLottieLayer;
        });
        const videoLottieConfigs: IVideoLottieCompose = {
            id: uuidv4(),
            basicLottieLayersOnTrack: updatedBasicLottieLayers,
            videoLayers: videoLayers,
            lottieData: lottieDynamicData,
        };

        const lottieComposedConfigs: IComposedEditTakeConfigs = {
            id: uuidv4(),
            startTime: startTime ? startTime / 1000 : 0,
            endTime: endTime ? endTime / 1000 : 0,
            videoLottieConfigs: [videoLottieConfigs],
        };
        console.log(`PLAYING NEXT SCENE FROM HERE`);

        const composeAndPlayOrNot: IComposeAndPlayOrNot = {
            composeConfigs: lottieComposedConfigs,
            playOrNot: true,
        };
        return composeAndPlayOrNot;
    }

    public getBasiclottieLayers(layouts: ILayout | ILayout[]) {
        if (!Array.isArray(layouts)) {
            layouts = [layouts];
        }

        const basicLottieLayers: IBasicLottieLayer[] = layouts.map((layout) => {
            const basicLottieLayer: IBasicLottieLayer = {
                lottieId: layout._id,
                lottieJsonPath: layout.lottiePath,
            };
            return basicLottieLayer;
        });
        return basicLottieLayers;
    }
}
