<!-- <div class="scrub-bar" (mousedown)="onMouseDown($event)">
  <div class="scrub-bar-progress" [style.width]="getScrubBarWidth()"></div>
</div> -->

<mat-slider class="slider" min="0" [max]="duration ?? vgApiMedia?.duration" step="0.1">
    <!-- (input)="onDragging($event)" -->
    <input
        matSliderThumb
        (valueChange)="onDragging($event)"
        (dragStart)="dragStarted($event)"
        (dragEnd)="dragEnded($event)"
        [ngModel]="navigateFromEditRoom ? this.currentTime : this.currentTimeFromVideo * 1000" />
</mat-slider>
