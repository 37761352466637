<div>
    <div
        class="list-row clickable"
        *ngFor="let technique of data.storytellingTechniqueList"
        [class.selected]="technique?.technique === data.selectedTechnique"
        [mat-dialog-close]="{ chosen: technique?.technique }">
        <div *ngIf="technique.technique === data.selectedTechnique" class="row-centered selected-marker">
            <mat-icon class="check-icon small-icon">check</mat-icon>
            Selected
        </div>
        <h4>
            {{ technique.technique ?? 'Natural' }}
        </h4>
        <div class="property-row">
            <div>{{ technique.description }}</div>
        </div>
        <!--        <div class="property-row" *ngIf="technique?.whyItWorks">-->
        <!--            <div class="property-title">Why it works:</div>-->
        <!--            <div>{{ technique.whyItWorks }}</div>-->
        <!--        </div>-->
        <!--        <div class="property-row" *ngIf="technique?.example">-->
        <!--            <div class="property-title">Example:</div>-->
        <!--            <div>{{ technique.example }}</div>-->
        <!--        </div>-->
    </div>
</div>
