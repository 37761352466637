import { Observable } from 'rxjs';
import { catchErrorTypedAsync } from '../services/functions-helper.service';

export function handleApiCallAsync<T>(observable: Observable<T>) {
    const promise = new Promise<T>((resolve, reject) => {
        observable.subscribe({
            next: (result: T) => {
                if (!result) {
                    return reject(`An unknown error occurred`);
                }
                return resolve(result);
            },
            error: (error) => {
                console.error(`An error occurred while processing the request. Error:`, error);
                return reject(error);
            },
        });
    });
    return catchErrorTypedAsync(promise);
}
