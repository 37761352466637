<div class="template-card gapped">
    <div class="template-card__video-container card">
        <vg-player
            #vgPlayer
            (play)="onMediaPlay()"
            (pause)="onMediaPause()"
            (mouseover)="onHover(vgPlayer)"
            (mouseleave)="onLeave(vgPlayer)">
            <video
                [muted]="true"
                [src]="this.baseCdnUrl + template.iconVideo"
                class="template-card__video"
                preload="metadata"
                [poster]="this.baseCdnUrl + template.iconPoster"
                #media
                [vgMedia]="media"
                id="singleVideo"
                crossorigin>
                Your browser does not support the video tag. (play)="onMediaPlay()" (pause)="onMediaPause()"
            </video>
        </vg-player>

        <div
            (mouseover)="onHover(vgPlayer)"
            *ngIf="this.template.iconPoster && !this.isPlaying"
            class="poster"
            [@fadeInOnEnter]="true"
            [@fadeOutOnLeave]="true"
            [style]="'background-image: url(' + this.baseCdnUrl + this.template.iconPoster + ')'"></div>
    </div>

    <div class="template-card__info gapped">
        <div class="template-card__info__title">
            {{ template.name }}
        </div>
        <div class="template-card__button-container">
            <button
                color="primary"
                class="template-card__use-button"
                mat-raised-button
                (click)="useTemplate.next(template)">
                Use it
            </button>
        </div>
    </div>
</div>
