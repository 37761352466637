import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { DbService } from '../../dexie/db.service';
import { BehaviorSubject } from 'rxjs';

export interface IProjectIndexConfigs {
    dbName: string;
    storeName: string;
    version: number;
}

export interface IProjectDexieSchema {
    id: string;
    position: number;
    arrayBuffer: ArrayBuffer;
}

export interface IProjectIndexData extends IProjectIndexConfigs {
    dexiePromise: Promise<Dexie>;
    projectIndexDBData: Promise<IProjectDexieSchema[]>;
}

@Injectable({
    providedIn: 'root',
})
export class SharedProjectDBService {
    private _currentProjectDexieSchemas$ = new BehaviorSubject<IProjectDexieSchema[]>(null);
    public currentProjectDexieSchemas$ = this._currentProjectDexieSchemas$.asObservable();

    private _currentProjectIndexData$ = new BehaviorSubject<IProjectIndexData>(null);
    public currentProjectIndexData$ = this._currentProjectIndexData$.asObservable();
    constructor(private dexieDB: DbService) {}
    public get getCurrentProjectIndexData() {
        return this._currentProjectIndexData$.value;
    }
    /**
     * To avoid initalizing the same db if existed we have this shared service.
     * @param indexDBName
     * @returns
     */
    public initializeAsync(projectDBData: IProjectIndexConfigs) {
        const { dbName, storeName, version } = projectDBData;
        const projectSchema: { [key: string]: string } = {
            [storeName]: '++id, position, arrayBuffer',
        };

        const dbConnectionPromise = this.getIndexDBConnectionAsync(dbName, projectSchema);
        const indexDBProjectDataPromise = this.setCurrentProjectIndexDataAsync(dbConnectionPromise, dbName, storeName);
        const projectData: IProjectIndexData = {
            dbName: dbName,
            storeName: storeName,
            version: version,
            dexiePromise: dbConnectionPromise,
            projectIndexDBData: indexDBProjectDataPromise,
        };
        this._currentProjectIndexData$.next(projectData);
        return projectData;
    }
    public getIndexDBConfigs(projectId: string): IProjectIndexConfigs {
        const data: IProjectIndexConfigs = {
            dbName: `${this.getProjectDBName(projectId)}`,
            storeName: 'local-videos',
            version: 1,
        };
        return data;
    }
    private async getIndexDBConnectionAsync(dbName: string, projectSchema: { [p: string]: string }) {
        return this.dexieDB.openDatabaseAsync(dbName, projectSchema);
    }

    public async setCurrentProjectIndexDataAsync(dexie: Promise<Dexie>, dbName: string, storeName: string) {
        await dexie;
        const currentProjectDexieData = await this.dexieDB.getTableDataAsync<IProjectDexieSchema>(dbName, storeName);
        this._currentProjectDexieSchemas$.next(currentProjectDexieData);
        return currentProjectDexieData;
    }

    public async removeProjectsFromIndexDBAsync(projectId: string) {
        try {
            const currentProjectIdDBName = this.getProjectDBName(projectId);
            await this.dexieDB.deleteDatabasesAsync(this.getStartOfDBName(), currentProjectIdDBName);
        } catch (error) {
            console.error(`Could not remove projects from indexdb, error:`, error);
            throw error;
        }
    }

    getProjectDBName(projectId: string) {
        return `${this.getStartOfDBName()}${projectId}`;
    }

    getStartOfDBName() {
        return 'project_';
    }
}
