import { Injectable } from '@angular/core';
import { CreativeAuthApiService } from '../../api/auth/creative-auth-api.service';
import { BehaviorSubject, of } from 'rxjs';
import { IDesignGroup } from '../../../models/design.model';
import { catchError, finalize, retry } from 'rxjs/operators';
import { IStorytellingTechnique, ITagAndSuggestions, IToneOfVoice, IVideoTag } from '../../../models/defines';
import { ConfigurationAuthApiService } from '../../api/auth/config-auth-api.service';

@Injectable({
    providedIn: 'root',
})
export class WizardManagerService {
    private _suggestedDesigns$ = new BehaviorSubject<IDesignGroup[]>(null);
    private _privateDesigns$ = new BehaviorSubject<IDesignGroup[]>(null);
    private _isLoadingDesigns$ = new BehaviorSubject<boolean>(false);

    private _allPromptSuggestions$ = new BehaviorSubject<ITagAndSuggestions[]>(null);
    private _isLoadingPromptSuggestions$ = new BehaviorSubject<boolean>(false);
    private _videoTags$ = new BehaviorSubject<IVideoTag[]>(null);
    public videoTags$ = this._videoTags$.asObservable();

    private _toneOfVoiceList$$ = new BehaviorSubject<IToneOfVoice[]>(null);
    public toneOfVoiceList$ = this._toneOfVoiceList$$.asObservable();

    private _storyTellingTeqniquesList$$ = new BehaviorSubject<IStorytellingTechnique[]>(null);
    public storyTellingTeqniquesList$ = this._storyTellingTeqniquesList$$.asObservable();

    public suggestedDesigns$ = this._suggestedDesigns$.asObservable();
    public privateDesigns$ = this._privateDesigns$.asObservable();
    public isLoadingDesigns$ = this._isLoadingDesigns$.asObservable();
    public allPromptSuggestions$ = this._allPromptSuggestions$.asObservable();
    public isLoadingPromptSuggestions$ = this._isLoadingPromptSuggestions$.asObservable();

    constructor(
        private creativeApi: CreativeAuthApiService,
        private configAPI: ConfigurationAuthApiService
    ) {
        this.generatePromptSuggestions();
        this.generateDesignsSuggestions();
        this.getToneOfVoiceList();
        this.getStoryTellingTeqniquesList();
    }

    getToneOfVoiceList() {
        this.configAPI.getTonesConfig().then((tonesConfig) => {
            if (tonesConfig && tonesConfig.tones?.length > 0) {
                this._toneOfVoiceList$$.next(tonesConfig.tones);
            }
        });
    }

    getStoryTellingTeqniquesList() {
        this.configAPI.getStorytellingTeqniquesConfig().then((storyTellingTeqniquesConfig) => {
            if (storyTellingTeqniquesConfig && storyTellingTeqniquesConfig.techniques?.length > 0) {
                this._storyTellingTeqniquesList$$.next(storyTellingTeqniquesConfig.techniques);
            }
        });
    }

    generateDesignsSuggestions() {
        this._isLoadingDesigns$.next(true);
        this.creativeApi.getFormatSuggestion().subscribe((res) => {
            this._suggestedDesigns$.next(res.public);
            this._privateDesigns$.next(res.private);
            this._isLoadingDesigns$.next(false);
        });
    }

    generatePromptSuggestions() {
        this._isLoadingPromptSuggestions$.next(true);

        this.creativeApi
            .getPromptsSuggestions()
            .pipe(
                retry(2), // 3 attempts
                catchError((error) => {
                    console.error('Error occurred:', error);

                    return of(null);
                }),
                finalize(() => {
                    this._isLoadingPromptSuggestions$.next(false);
                })
            )
            .subscribe((prompts) => {
                if (prompts?.length > 0) {
                    this._allPromptSuggestions$.next(prompts);
                    /// Take the video tags from the prompts objects
                    this._videoTags$.next(
                        prompts.map((x) => {
                            return x.tag;
                        })
                    );
                }
            });
    }
}
