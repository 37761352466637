import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IUserVideoIdea } from '../../../../../models/user';
import { ILayout } from '../../../../../models/defines';
import { createIconLayer } from '../../../../../services/functions-helper.service';
import { ArtDirectorService } from '../../../../../services/art-director.service';
import { DynamicItemType, IDynamicLottieChange } from 'lottie-json-helper/lib/types';

@Component({
    selector: 'video-idea-card',
    templateUrl: './video-idea-card.component.html',
    styleUrl: './video-idea-card.component.scss',
})
export class VideoIdeaCardComponent implements OnChanges, OnInit {
    @Input({ alias: 'videoIdea' }) videoIdea: IUserVideoIdea;
    @Input({ alias: 'dynamicValues' }) dynamicValues: IDynamicLottieChange[];
    extendedDynamicValues: IDynamicLottieChange[];
    @Input('dummy') dummy = false;
    videoCardIconLayout: ILayout;

    projectChagnes: IDynamicLottieChange[] = [];

    constructor(private artDirector: ArtDirectorService) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.videoIdea) {
            if (this.videoIdea) {
                this.projectChagnes = [
                    {
                        id: DynamicItemType.HEADER + 0,
                        type: DynamicItemType.HEADER,
                        position: 0,
                        value: this.videoIdea.shortTitle,
                        placeholder: '',
                    },
                ];
            }

            this.videoCardIconLayout = createIconLayer(this.videoIdea.designGroup, 'video-idea');
        }
        if (changes.dynamicValues) {
            this.extendedDynamicValues = [...this.dynamicValues, ...this.projectChagnes];
        }
    }
}
