import { ConfigurationService } from '../../../../../services/configuration.service';
import { ProjectStatusEnum } from '../../../../../models/defines';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/guards/authentication.service';

import { ProjectAuthApiService } from 'src/app/services/api/auth/projects/project-auth-api.service';
import { IProjectInDTO } from 'src/app/models/project/project-model';
import { ProfileService } from '../../../../../services/show/profile.service';
import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import { ArtDirectorService } from '../../../../../services/art-director.service';
import { ProjectGeneralRouterService } from '../../../../../services/project/routes/project-general-router.service';
import { ConfirmationModalComponent } from '../../../../../components/dialogs/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsNotifierService } from '../../../../../services/utils/analytics-notifier.service';
import { Subject, takeUntil } from 'rxjs';
import { ProjectMetadataManagerService } from '../../../../../services/state-management/project/project-metadata/project-metadata-manager.service';
import { UserStateManagerService } from '../../../../../services/state-management/user/user-state-manager.service';

export enum GroupByEnum {
    NONE = 'None',
    SERIES = 'Series',
}

@Component({
    selector: 'my-library',
    templateUrl: './my-library.component.html',
    styleUrls: ['./my-library.component.scss'],
})
export class MyLibraryComponent implements OnInit, OnDestroy {
    groupByEnum = GroupByEnum;
    loadingData: boolean = false;
    projects: IProjectInDTO[] = [];
    selectedGroupByOption = GroupByEnum.NONE;
    groupOptions = [GroupByEnum.NONE, GroupByEnum.SERIES];
    userImg = this.authentication.getUser().profileImgUrl;
    baseCdn: string;
    personalizedDynamics: IDynamicLottieChange[];
    lottieOptions = {
        path: 'assets/animations/production/rocket.json',
        autoplay: true,
        loop: true,
    };
    dataInitialized = false;
    tabs = ['All'];
    projectsInStatuses: Record<'All' | ProjectStatusEnum, IProjectInDTO[]> = {
        All: [],
        [ProjectStatusEnum.PLANNING]: [],
        [ProjectStatusEnum.RECORDING]: [],
        [ProjectStatusEnum.EDITING]: [],
        [ProjectStatusEnum.EXPORTING]: [],
        [ProjectStatusEnum.DONE]: [],
    };
    isDuplicateOrDeleteLoader = false;
    private onDestroy$: Subject<boolean> = new Subject();
    toDisableDuplicate: boolean = false;
    constructor(
        private router: Router,
        private cdRef: ChangeDetectorRef,
        public authentication: AuthenticationService,
        private activeRoute: ActivatedRoute,
        private config: ConfigurationService,
        public projectAuthApiService: ProjectAuthApiService,
        private profileService: ProfileService,
        private artDirector: ArtDirectorService,
        private projectGeneralRouterService: ProjectGeneralRouterService,
        public dialog: MatDialog,
        private analyticsNotifer: AnalyticsNotifierService,
        private projectMetadataManager: ProjectMetadataManagerService,
        private userStateManager: UserStateManagerService
    ) {
        this.baseCdn = this.config.baseCdnUrl;
    }

    ngOnInit(): void {
        this.projects.forEach((project) => {
            this.personalizedDynamics = this.artDirector.extractDynamicLottieByUserBranding(project);
        });
        this.projectMetadataManager.updatedProject$.pipe(takeUntil(this.onDestroy$)).subscribe((updatedProject) => {
            if (!updatedProject) {
                return;
            }
            this.initProjectStatus(updatedProject);

            this.projectMetadataManager.updateProjectsList(this.projects, updatedProject, null);
        });
        this.userStateManager.paymentStatus$.pipe(takeUntil(this.onDestroy$)).subscribe((paymentStatus) => {
            this.toDisableDuplicate = paymentStatus?.needToPay ?? false;
        });
        this.initializeTabs();
        this.getData();
        this.cdRef.detectChanges();
    }

    private async getData() {
        try {
            this.loadingData = true;
            // this.productions = await this.productionsApi.getAllProductions();

            this.projectAuthApiService.getProjectList$().subscribe({
                next: (projects) => {
                    if (!projects) {
                        return;
                    }
                    this.projects = projects;
                    /// Devide projects by status and fill projectsInStatuses

                    this.projects.forEach((project) => {
                        this.initProjectStatus(project);
                        // Also add to all projects
                    });
                    this.projectsInStatuses['All'] = this.projects;

                    console.log(this.projectsInStatuses);

                    this.dataInitialized = true;
                    this.loadingData = false;
                    // console.log('projects', this.projects);
                },
                error: (error) => {
                    `Could not get history recordings, error: ${error}`;
                },
            });
        } catch (ex) {
            console.error('Error ocoured while trying to get episodes');
            this.loadingData = false;
        } finally {
        }
    }

    private initProjectStatus(project: IProjectInDTO) {
        project.isProjectOwner = project.user.email === this.profileService.user$.value?.email;
        // Fill the array according to the status key
        if (!this.projectsInStatuses[project.statuses?.general]) {
            this.projectsInStatuses[project.statuses?.general] = [];
        }
        this.projectsInStatuses[project.statuses?.general].push(project);
    }

    createNewProduction(seriesId?: string) {
        this.router.navigate([{ outlets: { panel: ['new'] } }], {
            relativeTo: this.activeRoute.parent,
        });
    }

    goToProject(project: IProjectInDTO) {
        console.log('project', project);
        this.projectGeneralRouterService.goToAsync(project.statuses.general, project);
    }

    duplicateProject(projectId: string) {
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
            width: '550px',
            data: {
                title: {
                    htmlContent: 'Duplicate your project?',
                    align: 'center',
                    css: {
                        color: '',
                        style: {},
                    },
                },
                body: [],
                buttons: [
                    {
                        text: 'Cancel',
                        css: {
                            color: 'default',
                            style: {},
                        },
                        callback: () => {
                            dialogRef.close(); // Close the dialog when "Next" is clicked
                        },
                    },
                    {
                        text: 'Duplicate',
                        css: {
                            color: 'primary',
                            style: {},
                        },
                        callback: async () => {
                            this.analyticsNotifer.notifyEvent('Library Duplicate Project', {});
                            this.isDuplicateOrDeleteLoader = true;
                            this.projectAuthApiService
                                .duplicateProject$(projectId, true)
                                .subscribe((project: IProjectInDTO) => {
                                    this.projects = [project, ...this.projects];
                                    this.isDuplicateOrDeleteLoader = false;
                                });
                        },
                    },
                ],
            },
        });
    }

    initializeTabs() {
        this.tabs = [
            'All',
            ProjectStatusEnum.PLANNING,
            ProjectStatusEnum.RECORDING,
            ProjectStatusEnum.EDITING,
            ProjectStatusEnum.DONE,
        ];
    }

    deleteProject(projectId: string) {
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
            width: '550px',
            data: {
                title: {
                    htmlContent: 'Are you sure you want to delete your project?',
                    align: 'center',
                    css: {
                        color: '',
                        style: {},
                    },
                },
                body: [],
                buttons: [
                    {
                        text: 'Cancel',
                        css: {
                            color: 'default',
                            style: {},
                        },
                        callback: () => {
                            dialogRef.close(); // Close the dialog when "Next" is clicked
                        },
                    },
                    {
                        text: 'Delete',
                        css: {
                            color: 'warn',
                            style: {},
                        },
                        callback: async () => {
                            this.analyticsNotifer.notifyEvent('Library Delete Project', {});
                            this.isDuplicateOrDeleteLoader = true;
                            this.projectAuthApiService.deleteProject$(projectId).subscribe((projectId: string) => {
                                if (projectId) {
                                    this.projects = [...this.projects.filter((project) => project.id !== projectId)];
                                }
                                this.isDuplicateOrDeleteLoader = false;
                            });
                        },
                    },
                ],
            },
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
