import { Injectable } from '@angular/core';
import { UserProjectEventsService } from '../../../../socket/user/user-project/user-project-events.service';
import { Subject } from 'rxjs';

@Injectable()
export class UserProjectEventListenerService {
    private _projectUpdatedToFetch$ = new Subject<{ projectId: string }>();
    public projectUpdatedToFetch$ = this._projectUpdatedToFetch$.asObservable();

    constructor(private userProjectEventsService: UserProjectEventsService) {
        this.listenToProjectUpdated();
    }

    private listenToProjectUpdated() {
        this.userProjectEventsService.onProjectUpdated().subscribe((data) => {
            this._projectUpdatedToFetch$.next({ projectId: data.projectId });
        });
    }
}
