import { Injectable } from '@angular/core';
import { IConvertor } from '../../../../../models/project/project-model';
import {
    ISubtitleSettings,
    ISubtitleSettingsInDTO,
    ISubtitleSettingsOutDTO,
} from '../../../../../models/project/edit/subtitles/styles/subtitles-styles';

@Injectable({
    providedIn: 'root',
})
export class SubtitlesSettingsConverterService
    implements IConvertor<ISubtitleSettingsInDTO, ISubtitleSettings, ISubtitleSettingsOutDTO>
{
    constructor() {}

    inToLocal(input: ISubtitleSettingsInDTO): ISubtitleSettings {
        const localSubtitles: ISubtitleSettings = {
            id: input.id,
            name: input.name,
            type: input.type,
            style: input.style,
        };
        return localSubtitles;
    }
    localToOut(client: ISubtitleSettings): ISubtitleSettingsOutDTO {
        const outSubtitles: ISubtitleSettingsOutDTO = {
            id: client.id,
            name: client.name,
            type: client.type,
            style: client.style,
        };
        return outSubtitles;
    }
}
