import { Injectable, OnDestroy } from '@angular/core';
import {
    IConvertorAsync,
    IProject,
    IProjectConfigs,
    IProjectInDTO,
    IProjectOutDTO,
    Project,
} from 'src/app/models/project/project-model';
import { SceneConverterService } from './scene-converter.service';
import { EditConvertorService } from './edit/edit-convertor.service';
import { Subject } from 'rxjs';
import { SharedProjectDBService } from 'src/app/services/state-management/project/shared-project-indexdb.service';
import { ProfileService } from '../../show/profile.service';
import { ProjectRecordingTypeEnum } from '../../../models/defines';

@Injectable()
export class ProjectConverterService implements IConvertorAsync<IProjectInDTO, IProject, IProjectOutDTO>, OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(
        private sceneConverter: SceneConverterService,
        private editConvertor: EditConvertorService,
        private projectSharedDB: SharedProjectDBService,
        private userService: ProfileService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async inToLocalAsync(input: IProjectInDTO, waitForLocalVideos: boolean, streamId?: string): Promise<IProject> {
        if (!input) {
            console.error(`Cannot convert input project to project dto project because it's null.`);
            return;
        }

        const dbConfig = this.projectSharedDB.getIndexDBConfigs(input.id);
        /// Initializing the project in the index db (the function is checking if it was initialized before)
        const projectIndexData = this.projectSharedDB.initializeAsync(dbConfig);
        if (waitForLocalVideos) {
            try {
                await projectIndexData.projectIndexDBData;
            } catch (error) {
                console.error(`Could not initialize db for project ${input.id}`);
            }
        }

        const baseDesignGroupPath = input.designGroup.chosenDesign.basePath;
        const localScenes = await Promise.all(
            input.scenes?.map((inScene, index) =>
                this.sceneConverter.inToLocalAsync(
                    inScene,
                    baseDesignGroupPath,
                    waitForLocalVideos,
                    input.id,
                    projectIndexData,
                    index,
                    streamId
                )
            )
        );

        const localEdits =
            input.edits?.map((edit) => {
                return this.editConvertor.inToLocal(edit, localScenes, baseDesignGroupPath, input.id);
            }) || [];

        const projectConfigs: IProjectConfigs = {
            id: input.id,
            branding: input.branding,
            designGroup: input.designGroup,
            toMaxine: input.toMaxine,
            statuses: input.statuses,
            user: input.user,
            updatedAt: input.updatedAt,
            title: input.title,
            scenes: localScenes ?? [],
            creative: input.creative,
            icon: input.icon,
            name: input.name,
            createdAt: input.createdAt,
            edits: localEdits,
            indexDBData: projectIndexData,
            launchAnswers: input.launchAnswers,
            videoType: input.videoType,
            invites: input.invites ?? [],
            shortTitle: input.shortTitle,
            isProjectOwner: this.userService.user$.value?.email === input.user.email,
            isPlayground: input.isPlayground,
        };
        const newProject = new Project(projectConfigs);
        return newProject;
    }

    localToOut(client: IProject): IProjectOutDTO {
        if (!client) {
            console.error(`Cannot convert project to out dto project because it's null.`);
            return;
        }

        const outDTOScenes = client.scenes?.map((scene) => this.sceneConverter.localToOut(scene));

        const extendedProjectOutDTO: IProjectOutDTO = {
            id: client.id,
            user: {
                email: client.user.email,
                firstName: client.user.firstName,
                lastName: client.user.lastName,
            },
            branding: client.branding,
            designGroup: client.designGroup,
            scenes: outDTOScenes ?? [],
            toMaxine: client.toMaxine,
            statuses: client.statuses,
            creative: client.creative,
            name: client.name,
            icon: client.icon,
            title: client.title,
            createdAt: client.createdAt,
            updatedAt: client.updatedAt,
            edits: client.edits,
            launchAnswers: client.launchAnswers,
            videoType: client.videoType ?? ProjectRecordingTypeEnum.CAMERA,
            invites: client.invites,
            shortTitle: client.shortTitle,
            isPlayground: client.isPlayground,
        };

        return extendedProjectOutDTO;
    }
}
