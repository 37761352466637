<div class="dialog-container" *ngIf="template">
    <button tabindex="-1" mat-icon-button mat-dialog-close class="close-button">
        <mat-icon>close</mat-icon>
    </button>

    <div class="dialog-content">
        <div class="template-preview-and-scenes" *ngIf="template.scenes">
            <div class="template-preview" *ngIf="selectedScene">
                <vg-player #vgPlayer (play)="onMediaPlay()" (pause)="onMediaPause()">
                    <video
                        [src]="this.baseUrl + selectedScene.iconVideo"
                        class="template-card__video"
                        preload="metadata"
                        [poster]="this.baseUrl + selectedScene.iconPoster"
                        #media
                        [vgMedia]="media"
                        id="singleVideo"
                        crossorigin>
                        Your browser does not support the video tag.
                    </video>
                    <div (click)="requestPlayOrPause(!this.playOrPause.isPlaying)" class="play-pause-overlay">
                        <div class="icon-wrap">
                            <mat-icon
                                *ngIf="!this.playOrPause.isPlaying"
                                class="play-icon"
                                [@flipInXOnEnter]
                                [@bounceOutOnLeave]
                                >play_arrow
                            </mat-icon>
                        </div>
                    </div>
                </vg-player>
            </div>
            <div class="scenes">
                <ngx-slick-carousel #slickCarousel="slick-carousel" [config]="scenesSliderConfig" class="template-list">
                    <div
                        ngxSlickItem
                        class="template-card-wrap clickable"
                        (click)="previewScene(scene)"
                        *ngFor="let scene of template.scenes">
                        <div
                            class="template-card-wrap__image"
                            [ngStyle]="{
                                'background-image': 'url(' + this.baseUrl + scene.iconPoster + ')',
                            }"></div>
                        <div class="scene-card__title">{{ scene.name }}</div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <div class="description-area">
            <h3>Template: {{ template.name }}</h3>
            <div>{{ template.description }}</div>
            <button class="use-template-button" tabindex="1" mat-raised-button color="primary" (click)="useTemplate()">
                Use this template
            </button>
        </div>
    </div>
</div>
