import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColorPalettesService, IColorPalette } from '../../../../services/utils/color-palettes.service';
import { BrandingAuthApiService } from '../../../../services/api/auth/branding-auth-api.service';
import { IBranding } from '../../../../models/user';
import { ProfileService } from '../../../../services/show/profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { WalkthroughPanelService } from 'src/app/services/walkthrough-panel.service';
import { TaskTypeEnum } from 'src/app/models/walkthrough';
import { BrandingOnboardingComponent } from '../../onboarding/branding-onboarding/branding-onboarding.component';
import { MatDialog } from '@angular/material/dialog';
import { BrandingAutomaticImportComponent } from '../../onboarding/branding-onboarding/modes/branding-automatic-import/branding-automatic-import.component';
import { ConfirmationModalComponent } from '../../../../components/dialogs/confirmation-modal/confirmation-modal.component';
import { IBasicUserInfo } from '../../../../models/project/project-model';

@Component({
    selector: 'app-branding',
    templateUrl: './branding.component.html',
    styleUrls: ['./branding.component.scss'],
})
export class BrandingComponent implements OnInit {
    @Input()
    realPlatformMode = true;
    transparentLogoName: string;
    @Input()
    branding: IBranding;

    decodeUrl = decodeURIComponent(
        'https://content.shuffll.com/users-assets/aviv%40castingdepot.com%2Fassets%2Ffile%2F92f1015c-2f69-4c89-8413-46b767d96bc2.svg'
    );
    // branding: IBranding;

    displayWaterMark = true;
    assetBasePath: string;
    loadingData = true;
    selectedColorPallete: IColorPalette;
    collorPalleteFormControl = new FormControl();
    designFormControl = new FormControl();
    logoColorPallete: IColorPalette = {
        name: 'LOGO',
        value: { colorPrimary: '', colorSecondary: '', colorExtra: '' },
    };
    defaultPalette: IColorPalette = {
        name: 'Default',
        value: { colorPrimary: '', colorSecondary: '', colorExtra: '' },
    };
    customPalette: IColorPalette = { name: 'Custom', value: null };
    showCustomPallete = false;
    companyName = '';
    companyAbout = '';
    taskType: TaskTypeEnum = TaskTypeEnum.BRANDING;
    logoDisable = true;
    logoWDisable = true;
    isUploadingLogo = false;
    isFocused = false;
    constructor(
        private _snackBar: MatSnackBar,
        public colorPalettesService: ColorPalettesService,
        public brandingAPI: BrandingAuthApiService,
        public profile: ProfileService,
        private config: ConfigurationService,
        private walkthroughPanelService: WalkthroughPanelService,
        private dialog: MatDialog
    ) {
        this.assetBasePath = this.config.baseCdnUrl;
    }

    newColorSelected() {
        const newPallet = this.collorPalleteFormControl.value;
        if (newPallet) {
            this.branding.colors = newPallet.value;
            this.updateBranding('colors');
        }
    }

    defaultBranding() {
        this.branding.colors = {
            colorPrimary: '#84b636',
            colorSecondary: '#fff',
            colorExtra: '#84b636',
        };
        this.branding.logo = '/files/platform/nvidia-demo/nvidia_logo.png';
    }

    public updateBranding(property, updateMessage = null) {
        if (!this.realPlatformMode) {
            /// For local changes
            this.profile.updateUserBranding(this.branding);
            return;
        }
        /// We need to avoid sending multiple updates simultaniusly
        this.loadingData = true;
        this.brandingAPI.updateProperty(property, this.branding[property]).subscribe((x) => {
            // this.profile.user.branding = this.branding;

            if (updateMessage) {
                this._snackBar.open(updateMessage, 'Dismiss', { duration: 3000 });
                this.companyAbout = this.branding.about;
                if (this.branding.company.domain) {
                    this.companyName = this.branding.company.domain.split('.')[0];
                }

                this.profile.updateUserBranding(this.branding);
            }

            this.loadingData = false;
        });
    }

    saveCompanyStory() {
        this.branding.about = this.companyAbout;
        this.updateBranding('about', 'Brand Story Updated');
    }

    saveCompanyName() {
        this.branding.company = { name: this.companyName };
        this.updateBranding('company', 'Brand Name Updated');
    }

    getBrandingFromApi(updateToLogoColors: boolean) {
        this.isUploadingLogo = false;
        this.loadingData = true;
        this.brandingAPI.get().subscribe((branding: IBranding) => {
            this.loadBranding(branding, updateToLogoColors);
        });
    }

    importBranding() {
        // Open onboarding branding in a dialog
        this.dialog.open(BrandingOnboardingComponent, {
            width: '500px',
            height: '500px',
        });
    }

    public loadBranding(branding: IBranding, updateToLogoColors = false) {
        if (branding) {
            this.logoDisable = branding.logo.includes(this.transparentLogoName);
            this.logoWDisable = branding.logoW.includes(this.transparentLogoName);
            this.setDefaultColors(branding);

            this.branding = branding;

            if (updateToLogoColors) {
                this.setLogoColors(branding);
                this.collorPalleteFormControl.setValue(this.logoColorPallete);
                this.newColorSelected();
            }
            if (this.branding?.company?.domain) {
                this.companyName = this.branding.company.domain.split('.')[0];
            }
            this.companyAbout = this.branding?.about ?? '';
            this.companyName = this.branding?.company.name ?? '';
            if (!this.walkthroughPanelService.checkIfTaskCompleted(this.taskType)) {
                this.walkthroughPanelService.tasksCompleted$.next(this.taskType);
            }

            /// Update the user as well
        }
        this.loadingData = false;
    }

    ngOnInit(): void {
        this.transparentLogoName = '/files/platform/onboarding/transparent-d832b59d-b37f-4d2f-9797-a90335bd5d28.svg';
        if (this.profile.user$.value.branding?.company?.name) {
            this.companyName = this.profile.user$.value.branding.company.name;
        }
        this.designFormControl.setValue('Proffessional');
        this.designFormControl.disable();

        // this.getBrandingFromApi(false);

        if (this.realPlatformMode) {
            this.getBrandingFromApi(false);
        } else {
            /// Load from local
            if (this.branding) {
                console.log('loading default branding', this.branding);
                this.loadBranding(this.branding);
            }
        }
    }

    newDesignSelected() {
        this.updateBranding('design');
    }

    singleColorChange(ev) {
        console.log(ev);
        this.showCustomPallete = true;
        this.collorPalleteFormControl.setValue(this.customPalette, {
            emitEvent: false,
        });
        this.updateBranding('colors', 'Brand Colors Updated');
    }

    setDefaultColors(branding: IBranding) {
        // PREVIOUS COLORS
        this.defaultPalette.value.colorPrimary = branding.colors.colorPrimary;
        this.defaultPalette.value.colorExtra = branding.colors.colorExtra;
        this.defaultPalette.value.colorSecondary = branding.colors.colorSecondary;

        /// Set the picker to the previuos color selection - we call it 'default'
        this.collorPalleteFormControl.setValue(this.defaultPalette, {
            emitEvent: false,
        });

        ///LOGO COLORS
        this.setLogoColors(branding);
    }

    private setLogoColors(branding: IBranding) {
        if (branding.logoColors) {
            this.logoColorPallete.value.colorPrimary = branding.logoColors.colorPrimary;
            this.logoColorPallete.value.colorExtra = branding.logoColors.colorExtra;
            this.logoColorPallete.value.colorSecondary = branding.logoColors.colorSecondary;
        }
        this.profile.user$.value.branding = branding;
    }

    openImportBrandingComponent() {
        const dialogRef = this.dialog.open(BrandingAutomaticImportComponent, {
            width: '600px',
            height: '400px',
            data: {
                branding: this.branding,
            },
            panelClass: 'padding-modal',
        });
        dialogRef.afterClosed().subscribe((branding: IBranding) => {
            if (branding) {
                this.loadBranding(branding, true);
                this.profile.updateUserBranding(branding);
            }
        });
    }
    async deleteLogo(logo: 'logo' | 'logoW') {
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
            width: '550px',
            data: {
                title: {
                    htmlContent: 'Are you sure you want to delete your logo?',
                    align: 'center',
                    css: {
                        color: '',
                        style: {},
                    },
                },
                body: [
                    {
                        htmlContent: 'Deleting your logo will remove it from all application',
                        align: 'center',
                        css: {
                            color: '',
                            style: {},
                        },
                    },
                ],
                buttons: [
                    {
                        text: 'Cancel',
                        css: {
                            color: 'primary',
                            style: {},
                        },
                        callback: () => {
                            dialogRef.close(); // Close the dialog when "Next" is clicked
                        },
                    },
                    {
                        text: 'Delete Logo',
                        css: {
                            color: 'default',
                            style: {},
                        },
                        callback: async () => {
                            this.brandingAPI.deleteLogo(logo).subscribe((branding: IBranding) => {
                                this.loadBranding(branding, true);
                            });
                        },
                    },
                ],
            },
        });
    }
    onUploadLogoStarted() {
        this.isUploadingLogo = true;
    }
}
