import { Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../services/guards/authentication.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { WalkthroughPanelService } from 'src/app/services/walkthrough-panel.service';
import { AnalyticsNotifierService } from '../../services/utils/analytics-notifier.service';
import { MatDialog } from '@angular/material/dialog';
import { PathHelperService } from 'src/app/services/utils/path-helper.service';
import { NavListenersService } from '../../services/nav-listeners.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FeedbackEnum } from 'src/app/models/defines';
import { UserService } from 'src/app/services/api/auth/user.service';
import { User } from '../../models/user';
import { IntercomService } from '../../services/intercom.service';
import { PaymentDialogService } from '../../services/payment-dialog.service';
import { UserStateManagerService } from '../../services/state-management/user/user-state-manager.service';

enum PaymentButtonTextEnum {
    UPGRADE = 'Upgrade Now',
    FREE = '1 Free project left!',
}

interface IPaymentButtonConfig {
    matIcon: 'diamond_icon' | 'bolt';
    text: PaymentButtonTextEnum;
}

const paymentButtonMap: Record<'upgrade' | 'free', IPaymentButtonConfig> = {
    upgrade: { matIcon: 'diamond_icon', text: PaymentButtonTextEnum.UPGRADE },
    free: { matIcon: 'diamond_icon', text: PaymentButtonTextEnum.FREE },
};

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
    @Output('openStateChange') openStateChange$ = new EventEmitter<boolean>();
    toOpenWalkthrough: boolean = false;
    public _isOpen = new BehaviorSubject<boolean>(true);
    LOCAL_STORAGE_KEY = 'sideNavIsOpen';
    isShuffllUser: boolean;
    isMobileUser: boolean;
    FeedbackEnum = FeedbackEnum;
    user: User;
    profilePic: string;
    paymentButton: {
        matIcon: 'diamond_icon' | 'bolt';
        text: PaymentButtonTextEnum;
    } | null;
    onDestroy$ = new Subject<boolean>();

    constructor(
        public navListener: NavListenersService,
        private router: Router,
        public gtmService: AnalyticsNotifierService,
        public authentication: AuthenticationService,
        public config: ConfigurationService,
        public walkthroughPanelService: WalkthroughPanelService,
        private dialog: MatDialog,
        public pathHelperService: PathHelperService,
        private breakpointObserver: BreakpointObserver,
        private userService: UserService,
        private intercomeService: IntercomService,
        private paymentDialogService: PaymentDialogService,
        public userStateManagerService: UserStateManagerService
    ) {
        this.isMobileUser = this.userService.isMobileUser();

        const savedState = localStorage.getItem(this.LOCAL_STORAGE_KEY);
        const isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);

        // Set initial state based on device type, override if local storage has a value
        let shouldStartOpen: boolean;
        if (!savedState && !this.isMobileUser) {
            shouldStartOpen = true;
        } else {
            shouldStartOpen = savedState === 'true' && !isHandset;
        }
        // console.log(shouldStartOpen);
        this.userStateManagerService.paymentStatus$.pipe(takeUntil(this.onDestroy$)).subscribe((paymentStatus) => {
            if (!paymentStatus) {
                return;
            }

            if (!paymentStatus.needToPay) {
                this.paymentButton = null;
            }
            const configs = paymentStatus.needToPayNow ? paymentButtonMap.upgrade : paymentButtonMap.free;
            this.paymentButton = configs;
        });
        this._isOpen = new BehaviorSubject<boolean>(shouldStartOpen);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    set isOpen(value: boolean) {
        if (value !== this._isOpen.getValue()) {
            this._isOpen.next(value);
            this.openStateChange$.emit(value);
            localStorage.setItem(this.LOCAL_STORAGE_KEY, String(value));
        }
    }

    ngOnInit(): void {
        this.user = this.authentication.getUser();
        this.profilePic = this.pathHelperService.getUserProfileImage(this.user);
        this.walkthroughPanelService.walkthroughHidden$.subscribe({
            next: (response) => {
                this.toOpenWalkthrough = response;
            },
        });

        this.isShuffllUser = this.isShuffll();

        this.navListener.isSideNavOpen.subscribe((value) => {
            if (value !== null && value !== undefined) {
                this._isOpen.next(value);
            }
        });
    }

    /**
     * Open the Intercom bubble
     */
    openSupport() {
        this.intercomeService.open();

        this.gtmService.notifyEvent('Navbar - Open Support');
    }

    toggleIsOpen() {
        this.isOpen = !this._isOpen.value;
    }

    private isShuffll() {
        return this.authentication.getUser()?.isShuffllUser;
    }

    goToAdmin() {
        this.router.navigate([
            'dashboard',
            {
                outlets: {
                    panel: ['backoffice', { outlets: { 'admin-panel': ['users'] } }],
                },
            },
        ]);
    }

    public openPaymentsDialog() {
        this.paymentDialogService.openPaymentDialogAsync(false, 'sidenav', false);
    }
}
