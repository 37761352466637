import { animate, style, transition, trigger } from '@angular/animations';
import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation } from 'angular-animations';

export const StudioAnimations = [
    fadeInUpOnEnterAnimation(),
    fadeOutDownOnLeaveAnimation(),
    trigger('dynamicScene', [
        transition(':enter', [style({ opacity: 0 }), animate('200ms 300ms', style({ opacity: 1 }))]),
        transition(':leave', [animate('100ms', style({ width: '50%' }))]),
    ]),
    trigger('staticScene', [
        transition(':enter', [style({ opacity: 0 }), animate('200ms 200ms', style({ opacity: 1 }))]),
        transition(':leave', [animate('200ms', style({ width: '95%' }))]),
    ]),
];

export const StudioCarouselConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    centerMode: true,
    focusOnSelect: true,
    dots: false,
    variableWidth: true,

    // responsive: [
    //   {
    //     breakpoint: 1300,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 800,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
};
