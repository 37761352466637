import { Injectable } from '@angular/core';
import { IConvertorAsync } from 'src/app/models/project/project-model';
import { IVideoLayerInDTO, IVideoLayer, IVideoLayerOutDTO } from 'src/app/models/project/take/layers/video-model';
import { ITakeInDTO } from '../../../../../models/project/take/take-model';

@Injectable({
    providedIn: 'root',
})
export class VideoConvertorService implements IConvertorAsync<IVideoLayerInDTO, IVideoLayer, IVideoLayerOutDTO> {
    constructor() {}

    public async inToLocalAsync(inVideoLayer: IVideoLayerInDTO, take: ITakeInDTO, projectId: string) {
        if (!inVideoLayer) {
            console.error(`Cannot convert video layer to out dto video layer because it's null.`);
            return;
        }
        const videoLayer: IVideoLayer = {
            id: inVideoLayer.id,
            uinuqeId: `video layer id: ${inVideoLayer.id} - take id:${take.id}`,
            videoPosition: inVideoLayer.videoPosition,
            uploadPath: inVideoLayer.uploadPath,
            dashPath: inVideoLayer.dashPath,
            defaultTrimStart: inVideoLayer.defaultTrimStart,
            defaultTrimEnd: inVideoLayer.defaultTrimEnd,
            width: inVideoLayer.width,
            height: inVideoLayer.height,
            storeManagerId: projectId,
        };

        return videoLayer;
    }

    public localToOut(videoLayer: IVideoLayer) {
        if (!videoLayer) {
            console.error(`Cannot convert video layer to out dto video layer because it's null.`);
            return;
        }

        const outDTOVideoLayer: IVideoLayerOutDTO = {
            id: videoLayer.id,
            videoPosition: videoLayer.videoPosition,
            uploadPath: videoLayer.uploadPath,
            width: videoLayer.width,
            height: videoLayer.height,
        };
        return outDTOVideoLayer;
    }
}
